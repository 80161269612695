import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/modules/config.module";
import GlobalMixin from "@/core/mixin/globalMixin";
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
import VueFormulate from "@braid/vue-formulate";
import vuelidate from "vuelidate";
import vuelidateErrorExtractor from "vuelidate-error-extractor";
import Rollbar from "rollbar";

import VueNumeric from "vue-numeric";

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import VueSweetalert2 from "vue-sweetalert2";
import VueLodash from "vue-lodash";
import debounce from "lodash/debounce";
import BaseInput from "@/core/components/BaseInput";
import BasePassword from "@/core/components/BasePassword";

import KTCard from "@/view/content/Card.vue";
import BaseTable from "@/core/components/BaseTable";

Vue.config.productionTip = false;

window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;

Vue.use(VueFormulate);
Vue.use(VueNumeric);
Vue.use(vuelidate);
Vue.use(vuelidateErrorExtractor);

Vue.prototype.$rollbar = new Rollbar({
  environment: process.env.NODE_ENV,
  accessToken: "89a742497a314ceba1100ec9ea5323b8",
  captureUncaught: true,
  captureUnhandledRejections: true,
});

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.info(info);
  vm.$rollbar.error(err);
  throw err; // rethrow
};

// global mixin
Vue.mixin(GlobalMixin);

Vue.use(VueLodash, { name: "custom", lodash: { debounce } });

Vue.component(BaseInput.name, BaseInput);
Vue.component(BasePassword.name, BasePassword);
Vue.component(BaseTable.name, BaseTable);
Vue.component(KTCard.name, KTCard);

const options = {};
Vue.use(VueSweetalert2, options);

// API service init
ApiService.init();

import { APP_INFO } from "@/core/config/config";

const PROJECT_NAME = APP_INFO.LOGO_NAME;
const pageTitle = (metaTitle) => {
  if (metaTitle) {
    return PROJECT_NAME + " - " + metaTitle;
  } else {
    return PROJECT_NAME;
  }
};

router.beforeEach((to, from, next) => {
  document.title = pageTitle(to.meta.title);
  next();

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
