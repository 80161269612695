import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
// import JwtService from "@/core/services/jwt.service";

export const CREATE_TRAINEE_MODULE = "createTraineeModule";
export const UPDATE_TRAINEE_MODULE = "TraineeModuleUpdate";
export const DELETE_TRAINEE_MODULE = "TraineeModuleDelete";
export const GET_TRAINEE_MODULE_LIST = "getTraineeModuleList"; //by trainee_module_id
export const GET_ALL_TRAINEE_MODULE_LIST = "TraineeModuleList";
export const CREATE_MODULE_ITEM = "createModuleItem";

export const UPDATE_MODULE_ITEM = "UpdateModuleItem";
export const DELETE_MODULE_ITEM = "DeleteModuleItem";
export const GET_MODULE_ITEM_LIST = "getModuleItemList";
export const SET_TRAINEE_MODULE_LIST = "setTraineeModuleList";

// COURSE
export const CREATE_TRAINEE_COURSE = "createTraineeCourse";
export const UPDATE_TRAINEE_COURSE = "TraineeCourseUpdate";
export const DELETE_TRAINEE_COURSE = "TraineeCourseDelete";
export const GET_TRAINEE_COURSE_LIST = "getTraineeCourseList";
export const GET_ALL_TRAINEE_COURSE_LIST = "TraineeCourseList";
export const GET_LIST_COURSE_PROGRESS = "listCourseProgress";
export const GET_LIST_COURSE_ENROLL = "listCourseEnroll";
export const GENERATE_CERTIFICATE = "GenerateCertificate";

// MUTATION
export const SET_ALL_TRAINEE_MODULE_LIST = "setAllTraineeModuleList";
export const SET_MODULE_ITEM_LIST = "setModuleItemList";
export const SET_TRAINEE_COURSE_LIST = "setTraineeCourseList";
export const SET_ALL_TRAINEE_COURSE_LIST = "setAllTraineeCourseList";
export const SET_COURSE_PROGRESS_LIST = "setListCourseProgress";
export const SET_COURSE_ENROLL_LIST = "setListCourseEnroll"; //by trainee_course_id

const state = {
	traineeModuleList: null,
	allTraineeModuleList: null,
	moduleItemList: null,
	traineeCourseList: null,
	allTraineeCourseList: null,
	courseProgressList: null,
	courseEnrollList: null,
};

const getters = {};

const actions = {
	[CREATE_TRAINEE_MODULE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("createTraineeModule", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[UPDATE_TRAINEE_MODULE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("UpdateModuleItem", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[DELETE_TRAINEE_MODULE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("TraineeModuleDelete", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},

	[CREATE_MODULE_ITEM](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("createModuleItem", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[UPDATE_MODULE_ITEM](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("UpdateModuleItem", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[DELETE_MODULE_ITEM](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("DeleteModuleItem", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},

	[GET_TRAINEE_MODULE_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getTraineeModuleList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_TRAINEE_MODULE_LIST, data.data);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_ALL_TRAINEE_MODULE_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("TraineeModuleList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(
							SET_ALL_TRAINEE_MODULE_LIST,
							data.data.posts
						);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_MODULE_ITEM_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getModuleItemList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_MODULE_ITEM_LIST, data.data);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},

	// COURSE
	[CREATE_TRAINEE_COURSE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("createTraineeCourse", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[UPDATE_TRAINEE_COURSE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("TraineeCourseUpdate", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[DELETE_TRAINEE_COURSE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("TraineeCourseDelete", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_TRAINEE_COURSE_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getTraineeCourseList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(
							SET_TRAINEE_COURSE_LIST,
							data.data.posts
						);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_ALL_TRAINEE_COURSE_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("TraineeCourseList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						console.log(data.data);
						context.commit(
							SET_ALL_TRAINEE_COURSE_LIST,
							data.data.posts
						);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_LIST_COURSE_ENROLL](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("listCourseEnroll", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(
							SET_COURSE_PROGRESS_LIST,
							data.data.posts
						);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},

	[GET_LIST_COURSE_PROGRESS](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("listCourseProgress", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_COURSE_PROGRESS_LIST, data.data);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GENERATE_CERTIFICATE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("GenerateCertificate", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
};

const mutations = {
	[SET_TRAINEE_MODULE_LIST](state, data) {
		state.traineeModuleList = data;
	},
	[SET_ALL_TRAINEE_MODULE_LIST](state, data) {
		state.allTraineeModuleList = data;
	},
	[SET_MODULE_ITEM_LIST](state, data) {
		state.moduleItemList = data;
	},
	// COURSE
	[SET_TRAINEE_COURSE_LIST](state, data) {
		state.traineeCourseList = data;
	},
	[SET_COURSE_PROGRESS_LIST](state, data) {
		state.courseProgressList = data;
	},
	[SET_COURSE_ENROLL_LIST](state, data) {
		state.courseEnrollList = data;
	},
	[SET_ALL_TRAINEE_COURSE_LIST](state, data) {
		state.allTraineeCourseList = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
