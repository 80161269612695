<template>
  <component :is="tag">
    <div
      class="form-group"
      :class="[formGroupClass, { revealable: !hideReveal }]"
    >
      <label v-if="!hideLabel"
        >{{ label }}
        <i
          v-if="tooltipData"
          v-b-tooltip.html="tooltipData.message"
          :class="tooltipData.icon"
        ></i
      ></label>

      <b-input-group>
        <b-form-input
          :label="label"
          :type="fieldType"
          :class="[{ 'is-invalid': hasErrors }, 'form-control', extraClass]"
          :placeholder="placeholder"
          v-model="Input"
        ></b-form-input>
        <template v-if="!hideReveal" #append>
          <b-input-group-text
            :class="[{ 'is-invalid': hasErrors }]"
            @click="toggleReveal()"
            ><i :class="revealIcon"></i
          ></b-input-group-text>
        </template>
      </b-input-group>

      <div class="error-message" v-if="hasErrors">
        <div v-for="error in activeErrorMessages" :key="error">
          <i class="fa fa-exclamation-triangle"></i>
          {{ error }}
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

export default {
  name: "BasePassword",
  mixins: [singleErrorExtractorMixin],
  data() {
    return {
      fieldType: "password",
      revealIcon: "fa fa-eye",
    };
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    type: {
      type: String,
      default: "text",
    },
    maxLength: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipData: {
      type: Object,
      default: null,
    },
    hideReveal: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: "4",
    },
    options: {
      type: Array,
    },
    formGroupClass: {
      type: String,
      default: "",
    },
    extraClass: {
      type: String,
    },
  },
  methods: {
    toggleReveal() {
      this.fieldType = this.fieldType === "password" ? "text" : "password";
      this.revealIcon =
        this.revealIcon === "fa fa-eye" ? "fa fa-eye-slash" : "fa fa-eye";
    },
  },
  computed: {
    Input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control.revealable {
  border-right: 0;
}
.revealable {
  .form-control {
    border-right: 0;
  }
  .input-group-text {
    background-color: transparent;

    &.is-invalid {
      border-color: #d26a5c;
    }
  }
}
.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.85rem;
  color: #d26a5c;
}
.error-message {
  div {
    .fa-exclamation-triangle {
      color: #d26a5c;
    }
  }
}
</style>
