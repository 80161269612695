// Imports

// Constants
const USER_AUTH_KEY = "user_auth_key";
const USER_DATA = "userData";
const USER_SYSTEM_DATA = "userSystemType";

// save user authentication data
export const saveUserAuth = (data) => {
  window.localStorage.setItem(USER_AUTH_KEY, data);
};

// save user data
export const saveUserSystemData = (data) => {
  window.localStorage.setItem(USER_SYSTEM_DATA, data);
};

// get user auth data
export const getUserSystemData = () => {
  return window.localStorage.getItem(USER_SYSTEM_DATA);
};

// save user data
export const saveUserData = (data) => {
  window.localStorage.setItem(USER_DATA, data);
};

// get user auth data
export const getUserData = () => {
  return window.localStorage.getItem(USER_DATA);
};

// get user auth data
export const getUserSystemType = () => {
  let userData = JSON.parse(window.localStorage.getItem(USER_DATA));
  if (userData) {
    let userSystemType = userData.system_type_id;
    return userSystemType;
  } else {
    return 0;
  }
};

// get user auth data
export const clearLocalStorage = () => {
  window.localStorage.clear();
};

// check admin type
export const checkAdminType = () => {
  const data = JSON.parse(window.localStorage.getItem(USER_DATA));
  const maker = data.permission.find(
    (permission) => permission.maker_status == 1
  );
  const checker = data.permission.find(
    (permission) => permission.checker_status == 1
  );
  if (maker !== undefined && checker === undefined) {
    return "maker";
  } else if (maker === undefined && checker !== undefined) {
    return "checker";
  } else if (maker !== undefined && checker !== undefined) {
    return "makerChecker";
  } else {
    return null;
  }
};
// check user type
export const checkUserType = () => {
  const data = JSON.parse(window.localStorage.getItem(USER_DATA));
  const maker = data.permission.find(
    (permission) => permission.maker_status == 1
  );
  const checker = data.permission.find(
    (permission) => permission.checker_status == 1
  );
  if (maker !== undefined && checker === undefined) {
    return "maker";
  } else if (maker === undefined && checker !== undefined) {
    return "checker";
  } else {
    return null;
  }
};

// sidebar
export const sidebarPermission = (userType) => {
  // get user status
  let userStatus = JSON.parse(window.localStorage.getItem(USER_AUTH_KEY));

  if (userType == "trainee" && userStatus) {
    if (
      userStatus.isLoggedIn == true &&
      userStatus.isTrainee == true &&
      userStatus.isClientAdmin == false &&
      userStatus.isGlobalAdmin == false &&
      userStatus.isSuperAdmin == false
    ) {
      return 1;
    } else {
      return 0;
    }
  } else if (userType == "clientAdmin" && userStatus) {
    if (
      userStatus.isLoggedIn == true &&
      userStatus.isTrainee == false &&
      userStatus.isClientAdmin == true &&
      userStatus.isGlobalAdmin == false &&
      userStatus.isSuperAdmin == false
    ) {
      return 1;
    } else {
      return 0;
    }
  } else if (userType == "globalAdmin" && userStatus) {
    if (
      userStatus.isLoggedIn == true &&
      userStatus.isTrainee == false &&
      userStatus.isClientAdmin == false &&
      userStatus.isGlobalAdmin == true &&
      userStatus.isSuperAdmin == false
    ) {
      return 1;
    } else {
      return 0;
    }
  } else if (userType == "superAdmin" && userStatus) {
    if (
      userStatus.isLoggedIn == true &&
      userStatus.isTrainee == false &&
      userStatus.isClientAdmin == false &&
      userStatus.isGlobalAdmin == false &&
      userStatus.isSuperAdmin == true
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  return 0;
};

export default {
  saveUserAuth,
  getUserSystemType,
  saveUserData,
  getUserData,
  saveUserSystemData,
  getUserSystemData,
  checkAdminType,
  checkUserType,
  sidebarPermission,
  clearLocalStorage,
};
