import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
// import JwtService from "@/core/services/jwt.service";

export const REGISTER_GLOBAL_ADMIN = "registerGlobalAdmin";
export const GET_GLOBAL_ADMIN_USER_LIST = "getGlobalAdminUserList";
export const GET_ROUTE_PERMISSION = "getRoutePermission";
export const UPDATE_ROUTE_PERMISSION = "updateRoutePermission";
export const ASSIGN_GLOBAL_ADMIN_GENERAL_PERMISSION =
  "assignGlobalAdminGeneralPermission";
export const REGISTER_SUPER_ADMIN = "registerSuperAdmin";

export const GET_ADMIN_PERMISSION = "getAdminPermission";
export const UPDATE_ADMIN_PERMISSION = "updateAdminPermission";

// mutation types
export const SET_GLOBAL_ADMIN_USER_LIST = "setGlobalAdminUserList";
export const SET_ROUTE_PERMISSION = "setRoutePermission";
export const SET_ADMIN_LIST = "setAdminList";
export const SET_ADMIN_PERMISSION = "setAdminPermission";

const state = {
  globalAdminUserList: null,
  routePermission: [],
  adminList: [],
  adminPermission: [],
};

const getters = {};

const actions = {
  [REGISTER_GLOBAL_ADMIN](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("registerGlobalAdmin", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_GLOBAL_ADMIN_USER_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getGlobalAdminUserList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_GLOBAL_ADMIN_USER_LIST, data.data.posts);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_ROUTE_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getRoutePermissionList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            const permissions = data.data;
            if (permissions) {
              context.commit(SET_ROUTE_PERMISSION, permissions);
            }
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPDATE_ROUTE_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("updateRoutePermissionList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_ADMIN_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getGlobalAdminPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_ADMIN_PERMISSION, data.data.fullUpdatedList);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPDATE_ADMIN_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("updateGlobalAdminPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [ASSIGN_GLOBAL_ADMIN_GENERAL_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("assignGlobalAdminGeneralPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_GLOBAL_ADMIN_USER_LIST](state, data) {
    state.globalAdminUserList = data;
  },
  [SET_ROUTE_PERMISSION](state, permissions) {
    state.routePermission = permissions;
  },
  [SET_ADMIN_LIST](state, admin) {
    state.adminList = admin;
  },
  [SET_ADMIN_PERMISSION](state, permission) {
    state.adminPermission = permission;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
