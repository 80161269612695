const UiHelper = {
  passwordTooltips() {
    return `<div>Password must have: <ul>
            <li>at least 1 lowercase character</li>
            <li>at least 1 uppercase character</li>
            <li>at least 1 numerical character</li>
            <li>at least 1 of these symbol: <br>! @ # $ % ^ & *</li>
            <li>minimum of 8 characters</li>
            </ul>
        </div>`;
  },
  ssmTooltips() {
    return `<div>Your company registration number must have:<ul>
            <li>Maximum length of 12 digits</li>
            <li>Alphabets and numbers only</li>
            <li>Without (-) characters</li>
            </ul>
        </div>`;
  },
  submissionNameTooltips() {
    return `<div>Example:<ul>
            <li>New Staff for February 2021</li>
            <li>Claims February 2021</li>
            <li>OT February 2021</li>
            </ul>
        </div>`;
  },
  validationMessages() {
    let message = {
      required: "{attribute} cannot be empty",
      email: "Invalid email address",
      validatePassword: "Password does not meet required criteria",
      minLength: "{attribute} too short",
      maxLength: "{attribute} too Long",
      sameAsPassword: "{attribute} not the same as previous field",
      validateSSM: "{attribute} is not valid",
      alphaNum: "{attribute} accept alphabets or numbers only",
      numeric: "{attribute} accepts numbers only",
      validatePhoneNumbers: "{attribute} field invalid",
    };
    return message;
  },
};

export default UiHelper;
