import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
// import JwtService from "@/core/services/jwt.service";

export const COMPANY_USER_PRE_REGISTRATION = "companyUserPreRegistration";
export const APPROVE_COMPANY_USER_REGISTRATION =
  "approveCompanyUserRegistration";
export const REJECT_COMPANY_USER_REGISTRATION = "rejectCompanyUserRegistration";
export const DELETE_COMPANY_USER = "deleteCompanyUserRegistration";
export const UPDATE_COMPANY_USER = "UpdateCompanyUser";

export const GET_COMPANY_USER_PRE_REGISTRATION_LIST =
  "getCompanyUserPreRegistrationList";
export const GET_COMPANY_USER_PRE_REG_APPROVAL_LIST =
  "getCompanyUserPreRegList";
export const GET_PENDING_COMPANY_REGISTRATION = "getPendingCompanyRegistration";
export const GET_APPROVED_COMPANY_REGISTRATION =
  "getApprovedCompanyRegistration";
export const GET_REJECTED_COMPANY_REGISTRATION =
  "getRejectedCompanyRegistration";
export const GET_COMPANY_USER_LIST = "getCompanyUserList";
export const GET_COMPANY_LIST = "getCompanyList";
export const GET_REGISTRATION_INFORMATION_LIST =
  "getRegistrationInformationList";
export const GET_COMPANY_REGISTRATION_APPROVAL_LIST =
  "getCompanyRegistrationApprovalList";
export const GA_GET_COMPANY_DETAILS = "globalAdminGetCompanyDetails";

export const ADD_NEW_COMPANY_REGISTRATION_INFORMATION =
  "addNewCompanyRegistrationInformation";
export const APPROVE_REGISTRATION_INFORMATION =
  "approveRegistrationInformation";
export const REJECT_REGISTRATION_INFORMATION = "rejectRegistrationInformation";

export const GET_CLIENT_ADMIN_PERMISSION = "getClientAdminPermissionList";
export const UPDATE_CLIENT_ADMIN_PERMISSION = "updateClientAdminPermission";
export const ASSIGN_CLIENT_ADMIN_GENERAL_PERMISSION =
  "assignClientAdminGeneralPermission";

// mutation types
export const SET_COMPANY_USER_PRE_REGISTRATION_LIST =
  "setCompanyUserPreRegLIst";
export const SET_COMPANY_USER_PRE_REG_APPROVAL_LIST =
  "setCompanyUserPreRegApprovalList";
export const SET_PENDING_COMPANY_REGISTRATION = "setPendingCompanyRegistration";
export const SET_APPROVED_COMPANY_REGISTRATION =
  "setApprovedCompanyRegistration";
export const SET_REJECTED_COMPANY_REGISTRATION =
  "setRejectedCompanyRegistration";
export const SET_COMPANY_USER_LIST = "setCompanyUserList";
export const SET_COMPANY_LIST = "setCompanyList";
export const SET_GA_COMPANY_DETAILS = "setGACompanyDetails";
export const SET_REGISTRATION_INFORMATION_LIST =
  "setRegistrationInformationList";
export const SET_COMPANY_REGISTRATION_APPROVAL_LIST =
  "setCompanyRegistrationApprovalList";
export const SET_CLIENT_ADMIN_PERMISSION = "setClientAdminPermissionList";

const state = {
  companyUserPreRegistrationList: null,
  companyUserPreRegApprovalList: null,
  pendingCompanyRegistration: null,
  approvedCompanyRegistration: null,
  rejectedCompanyRegistration: null,
  companyUserList: null,

  clientAdminPermissionList: null,

  companyList: null,
  companyRegApprovalList: null,
  companyDetailsGA: null,
  registrationInformationList: null,
};

const getters = {};

const actions = {
  [GET_COMPANY_USER_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getCompanyUserList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(
              SET_COMPANY_USER_LIST,
              data.data.posts // datatable
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY_USER_PRE_REG_APPROVAL_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getCompanyUserRegistrationApproval", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_PENDING_COMPANY_REGISTRATION](context, payload) {
    payload.q = "PENDING";
    return new Promise((resolve) => {
      context
        .dispatch(GET_COMPANY_USER_PRE_REG_APPROVAL_LIST, payload)
        .then((res) => {
          if (res.response_code == 2100) {
            context.commit(
              SET_PENDING_COMPANY_REGISTRATION,
              res.data.list.data
            );
            resolve(res);
          } else {
            resolve(res);
          }
        });
    });
  },
  [GET_APPROVED_COMPANY_REGISTRATION](context, payload) {
    payload.q = "APPROVED_HISTORY_LIST";
    return new Promise((resolve) => {
      context
        .dispatch(GET_COMPANY_USER_PRE_REG_APPROVAL_LIST, payload)
        .then((res) => {
          if (res.response_code == 2100) {
            context.commit(
              SET_APPROVED_COMPANY_REGISTRATION,
              res.data.list.data
            );
            resolve(res);
          } else {
            resolve(res);
          }
        });
    });
  },
  [GET_REJECTED_COMPANY_REGISTRATION](context, payload) {
    payload.q = "REJECTED";
    return new Promise((resolve) => {
      context
        .dispatch(GET_COMPANY_USER_PRE_REG_APPROVAL_LIST, payload)
        .then((res) => {
          if (res.response_code == 2100) {
            context.commit(
              SET_REJECTED_COMPANY_REGISTRATION,
              res.data.list.data
            );
            resolve(res);
          } else {
            resolve(res);
          }
        });
    });
  },
  [GET_COMPANY_USER_PRE_REGISTRATION_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getTempCompanyUserRegistrationList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(
              SET_COMPANY_USER_PRE_REGISTRATION_LIST,
              data.data.temp_company_user_registrations
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [COMPANY_USER_PRE_REGISTRATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("createTempCompanyUserRegistration", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [APPROVE_COMPANY_USER_REGISTRATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("approveCompanyUserRegistration", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [REJECT_COMPANY_USER_REGISTRATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("rejectCompanyRegistration", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [DELETE_COMPANY_USER](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("deleteCompanyUser", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPDATE_COMPANY_USER](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("updateCompanyUser", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [GET_COMPANY_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getCompanyList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_COMPANY_LIST, data.data.posts);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [APPROVE_REGISTRATION_INFORMATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("approveRegistrationInformation", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [REJECT_REGISTRATION_INFORMATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("rejectRegistrationInformation", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [ADD_NEW_COMPANY_REGISTRATION_INFORMATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("addNewCompanyRegistrationInformation", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_REGISTRATION_INFORMATION_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getRegistrationInformationList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_REGISTRATION_INFORMATION_LIST, data.data.list);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY_REGISTRATION_APPROVAL_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getCompanyRegistrationApproval", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(
              SET_COMPANY_REGISTRATION_APPROVAL_LIST,
              data.data.list
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GA_GET_COMPANY_DETAILS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("globalAdminGetCompanyDetails", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_GA_COMPANY_DETAILS, data.data.list);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  // CA PERMISSION
  [GET_CLIENT_ADMIN_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getClientAdminPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(
              SET_CLIENT_ADMIN_PERMISSION,
              data.data.fullUpdatedList
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [ASSIGN_CLIENT_ADMIN_GENERAL_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("autoAssignClientAdminGeneralPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPDATE_CLIENT_ADMIN_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("updateClientAdminPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  //Company User
  [SET_COMPANY_USER_PRE_REGISTRATION_LIST](state, data) {
    state.companyUserPreRegistrationList = data;
  },
  [SET_COMPANY_USER_PRE_REG_APPROVAL_LIST](state, data) {
    state.companyUserPreRegApprovalList = data;
  },
  [SET_PENDING_COMPANY_REGISTRATION](state, data) {
    state.pendingCompanyRegistration = data;
  },
  [SET_APPROVED_COMPANY_REGISTRATION](state, data) {
    state.approvedCompanyRegistration = data;
  },
  [SET_REJECTED_COMPANY_REGISTRATION](state, data) {
    state.rejectedCompanyRegistration = data;
  },
  [SET_COMPANY_USER_LIST](state, data) {
    state.companyUserList = data;
  },

  //Company
  [SET_COMPANY_LIST](state, data) {
    state.companyList = data;
  },
  [SET_COMPANY_REGISTRATION_APPROVAL_LIST](state, data) {
    state.companyRegApprovalList = data;
  },
  [SET_GA_COMPANY_DETAILS](state, data) {
    state.companyDetailsGA = data;
  },
  [SET_REGISTRATION_INFORMATION_LIST](state, data) {
    state.registrationInformationList = data;
  },

  //CA Permission
  [SET_CLIENT_ADMIN_PERMISSION](state, data) {
    state.clientAdminPermissionList = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
