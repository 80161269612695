import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
// import JwtService from "@/core/services/jwt.service";

// ACTIONS
export const GET_QUESTION_ANSWER_LIST = "getQuestionAnswerList";
export const GET_QUESTION_LIST = "getQuestionList";
export const CREATE_QUESTION_ANSWER_OPTIONS = "createQuestionAnswerOption";
export const UPDATE_QUESTION_ANSWER_OPTIONS = "UpdateQuestionAnswerOption";
export const DELETE_QUESTION_ANSWER_OPTIONS = "DeleteQuestionAnswerOption";

export const GET_ALL_QUIZ_LIST = "QuizList";
export const GET_QUIZ_LIST = "getQuizList";
export const CREATE_QUIZ = "createQuiz";
export const UPDATE_QUIZ = "UpdateQuiz";
export const DELETE_QUIZ = "DeleteQuiz";

export const UPLOAD_MATERIAL = "uploadMaterial";
export const GET_MATERIAL_LIST = "getMaterialList";
export const GET_GA_VIEW_MATERIAL = "getGaViewMaterial";
export const UPDATE_MATERIAL = "UpdateMaterial";
export const DELETE_MATERIAL = "DeleteMaterial";

export const GET_TRAINEE_MODULE_DATA = "getTraineeModuleData";

// MUTATION
export const SET_QUESTION_ANSWER_LIST = "setQuestionAnswerList";
export const SET_QUESTION_LIST = "setQuestionList";

export const SET_ALL_QUIZ_LIST = "setAllQuizList";
export const SET_QUIZ_LIST = "setQuizList";
export const SET_QUIZ_DATA = "setQuizData";

export const SET_MATERIAL_LIST = "setMaterialList";
export const SET_GA_VIEW_MATERIAL = "setGaViewMaterial";

export const SET_TRAINEE_MODULE_DATA = "setTraineeModuleData";

const state = {
	questionAnswerList: null,
	questionList: null,
	allQuizList: null,
	quizList: null,
	quizData: {},
	allMaterialList: null,
	gaViewMaterial: {},
	traineeModuleData: {},
};

const getters = {};

const actions = {
	[CREATE_QUESTION_ANSWER_OPTIONS](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("createQuestionAnswerOption", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[UPDATE_QUESTION_ANSWER_OPTIONS](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("UpdateQuestionAnswerOption", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[DELETE_QUESTION_ANSWER_OPTIONS](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("DeleteQuestionAnswerOption", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_QUESTION_ANSWER_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getQuestionAnswerList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_QUESTION_ANSWER_LIST, data.data);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_QUESTION_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("QuestionList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(
							SET_QUESTION_LIST,
							data.data.questionsList
						);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},

	[CREATE_QUIZ](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("createQuiz", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[UPDATE_QUIZ](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("UpdateQuiz", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[DELETE_QUIZ](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("DeleteQuiz", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_ALL_QUIZ_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("QuizList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(
							SET_ALL_QUIZ_LIST,
							data.data.quizzesList
						);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_QUIZ_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getQuizList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
						context.commit(SET_QUIZ_DATA, data.data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},

	[UPLOAD_MATERIAL](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("UploadMaterial", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[UPDATE_MATERIAL](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("UpdateMaterial", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[DELETE_MATERIAL](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("DeleteMaterial", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_MATERIAL_LIST](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getMaterialList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_MATERIAL_LIST, data.data.posts);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_GA_VIEW_MATERIAL](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getGlobalAdminViewMaterial", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_GA_VIEW_MATERIAL, data.data);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_TRAINEE_MODULE_DATA](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getTraineeModuleList", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_TRAINEE_MODULE_DATA, data.data);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
};

const mutations = {
	[SET_QUESTION_ANSWER_LIST](state, data) {
		state.questionAnswerList = data;
	},
	[SET_QUESTION_LIST](state, data) {
		state.questionList = data;
	},

	[SET_ALL_QUIZ_LIST](state, data) {
		state.allQuizList = data;
	},
	[SET_QUIZ_LIST](state, data) {
		state.quizList = data;
	},
	[SET_QUIZ_DATA](state, data) {
		state.quizData = data;
	},
	[SET_MATERIAL_LIST](state, data) {
		state.allMaterialList = data;
	},
	[SET_GA_VIEW_MATERIAL](state, data) {
		state.gaViewMaterial = data;
	},
	[SET_TRAINEE_MODULE_DATA](state, data) {
		state.traineeModuleData = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
