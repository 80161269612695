import Vue from "vue";
import store from "@/core/services/store";
import Router from "@/router";
import axios from "axios";
import { APP_SETTINGS } from "@/core/config/config";

// Parse permissions error

// const URL = APP_SETTINGS.BASE_URL + APP_SETTINGS.MOBILE_VERSION;
// let sc = null;
// axios.get(URL + 'referencecode').then((res) => {
// 	// console.log(res);
// 	sc = res.data.data;
// 	console.log(sc);
// });

const SystemHelper = {
  init() {
    console.log("responseHelperLoaded");
  },
  async parsePermission(permissions) {
    // get system Constants
    const URL =
      APP_SETTINGS.BASE_URL + APP_SETTINGS.VERSION + "getReferenceCode";
    let systemconstants = null;
    const res = await axios.get(URL).then((res) => {
      systemconstants = res.data.data;
      // create message
      let message =
        "<em class='small'>Require ONE of the following permission(s): </em> <br>";
      let tableItems = [];

      if (Array.isArray(permissions) && permissions.length) {
        permissions.forEach((permission) => {
          if (permission.system_type_id == 1) {
            let module = Object.keys(
              systemconstants["CustomerPermissionReference"]
            ).find(
              (key) =>
                systemconstants.CustomerPermissionReference[key] ==
                permission.module_id
            );
            let right = Object.keys(systemconstants["RightStatus"]).find(
              (key) => systemconstants.RightStatus[key] == permission.right_id
            );

            tableItems.push({
              system: "User",
              module: module,
              right: right,
            });
          }

          if (permission.system_type_id == 2) {
            let module = Object.keys(
              systemconstants["AdminPermissionReference"]
            ).find(
              (key) =>
                systemconstants.AdminPermissionReference[key] ==
                permission.module_id
            );
            let right = Object.keys(systemconstants["RightStatus"]).find(
              (key) => systemconstants.RightStatus[key] == permission.right_id
            );

            tableItems.push({
              system: "Admin",
              module: module,
              right: right,
            });
          }
        });

        // create table
        let table =
          '<table class="mt-2 table table-bordered"> <thead class="thead-light"> ' +
          "<tr> " +
          '<th style="font-size: 0.7rem" scope="col">SYSTEM TYPE</th> ' +
          '<th style="font-size: 0.7rem" scope="col">MODULE</th> ' +
          '<th style="font-size: 0.7rem" scope="col">RIGHT</th> ' +
          "</tr> </thead> <tbody>";
        tableItems.forEach((item) => {
          table += `<tr> <td class="small"> ${
            item.system
          } </td> <td class="small"> ${item.module.replace(
            /_/g,
            " "
          )} </td>  <td class="small"> ${item.right.replace(
            /_/g,
            " "
          )} </td> </tr>`;
        });
        message += table;

        console.log(message);
        return message;
      } else {
        return "This endpoint does not contain any permission. Please contact Administrator";
      }
    });
    return res;
  },
  handleCatch(error) {
    let param;
    let defaultParam = {
      title: "Caught Error",
      confirmButtonText: "Confirm",
      customClass: {
        container: "confirmation-swal",
        header: "bg-danger",
        confirmButton: "btn btn-sm btn-danger ml-1",
        input: "form-control",
      },
    };

    if (error.response) {
      param = {
        title: "CATCH - " + error.response.status + " RESPONSE ERROR",
        html: error.message,
      };
    } else if (error.request) {
      param = {
        title: "CATCH - REQUEST ERROR",
        html: "The request was made but no response was received.",
      };
    } else {
      param = {
        title: "CATCH - Error" + error.message,
        html: "Please refer console",
      };
    }

    defaultParam = {
      ...defaultParam,
      ...param,
    };

    Vue.swal(defaultParam);
  },
  confirmationDialogHandler(
    param,
    vuexAction,
    vuexCallback,
    modalId = null,
    formRef
  ) {
    let defaultParam = {
      title: "Are You Sure?",
      showCancelButton: true,
      reverseButtons: true,
      allowOutsideClick: false,
      backdrop: true,
      heightAuto: false,
      confirmButtonText: "Confirm",
      html: "Are you sure?",
      customClass: {
        container: "confirmation-swal",
        header: "bg-primary",
        confirmButton: "btn btn-sm btn-primary ml-3",
        cancelButton: "btn btn-sm btn-secondary ml-3",
        input: "form-control",
      },
    };

    if (param) {
      defaultParam = {
        ...defaultParam,
        ...param,
      };
    }

    return new Promise((resolve, reject) => {
      Vue.swal(defaultParam).then((res) => {
        if (res.value) {
          if (vuexAction.payload) {
            store
              .dispatch(vuexAction.action, vuexAction.payload)
              .then(async (res) => {
                if (res.response_code === 2100) {
                  SystemHelper.successHandler(res, null, "success");

                  if (Array.isArray(vuexCallback) && vuexCallback.length) {
                    for (const callback of vuexCallback) {
                      if (callback.payload) {
                        await store.dispatch(callback.action, callback.payload);
                      } else {
                        await store.dispatch(callback.action);
                      }
                    }

                    // vuexCallback.forEach(async (callback) => {
                    // 	if (callback.payload) {
                    // 		await store.dispatch(callback.action, callback.payload);
                    // 	} else {
                    // 		await store.dispatch(callback.action);
                    // 	}
                    // });
                  }

                  resolve(res);

                  if (modalId != null) {
                    store.dispatch("setModalState", null);
                  }
                  if (formRef != null) {
                    store.dispatch("setActiveForm", formRef);
                  }
                } else {
                  SystemHelper.errorHandler(res.data, null, "error");
                  resolve(res);
                }
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            store.dispatch(vuexAction.action).then(async (res) => {
              if (res.data.response_code === 2100) {
                await SystemHelper.successHandler(res.data, null, "success");
                if (Array.isArray(vuexCallback) && vuexCallback.length) {
                  for (const callback of vuexCallback) {
                    if (callback.payload) {
                      await store.dispatch(callback.action, callback.payload);
                    } else {
                      await store.dispatch(callback.action);
                    }
                  }
                  // if (vuexCallback.payload) {
                  //   store.dispatch(vuexCallback.action, vuexCallback.payload);
                  // } else {
                  //   store.dispatch(vuexCallback);
                  // }
                }
                resolve(res);
              } else {
                if (res.data.newToken) {
                  await SystemHelper.errorHandler(res.data, null, "error");
                } else {
                  await SystemHelper.errorHandler(res.data, "logout", "error");
                }
                resolve(res);
              }
            });
          }
        } else {
          resolve(res);
        }
      });
    });
  },

  async responseCodeProcessor(resp) {
    let responseCode = resp.response_code || "";
    let errorMessage = resp.description;
    let validationErrors = null;
    if (typeof resp.data !== "undefined") {
      validationErrors = resp.data.validationErrors;
    }

    let tempHtml = "";

    if (typeof errorMessage === "undefined") {
      errorMessage = "";
    }
    let fatality = false;
    let redirect = false;
    let routePermissions = {};

    switch (responseCode) {
      case 2111:
        errorMessage = "ERROR";
        fatality = false;
        redirect = false;
        break;
      case 2100:
        errorMessage = "SUCCESS";
        fatality = false;
        redirect = false;
        break;
      case 2222:
        errorMessage = "GENERALERROR";
        fatality = false;
        redirect = false;
        break;
      case 3333:
        errorMessage = "DBQUERYERROR";
        fatality = false;
        redirect = false;
        break;
      case 4444:
        errorMessage = "NOTFOUND";
        fatality = false;
        redirect = false;
        break;
      case 5555:
        errorMessage = "NOT AUTHORIZED";
        fatality = false;
        redirect = false;
        break;
      case 6666:
        errorMessage = "JWT TOKEN INVALID";
        fatality = false;
        redirect = false;
        break;
      case 7777:
        errorMessage = "JWT TOKEN EXPIRED";
        fatality = false;
        redirect = false;
        break;
      case 8888:
        errorMessage = "JWT TOKEN NOT FOUND";
        fatality = false;
        redirect = false;
        break;
      case 9999:
        errorMessage = "MAINTENANCE MODE";
        fatality = false;
        redirect = false;
        break;
      case 10001:
        errorMessage = "ERROR UNIQUE USERNAME";
        fatality = false;
        redirect = false;
        break;
      case 10002:
        errorMessage = "ERROR UNIQUE EMAIL";
        fatality = false;
        redirect = false;
        break;
      case 10003:
        errorMessage = "ERROR USERNAME PATTERN";
        fatality = false;
        redirect = false;
        break;
      case 10004:
        errorMessage = "UNAUTHORIZED";
        fatality = false;
        redirect = false;
        break;
      case 10005:
        errorMessage = "VALIDATION ERROR";
        fatality = false;
        redirect = false;
        break;
      case 10006:
        errorMessage = "QUERY EMPTY";
        fatality = false;
        redirect = false;
        break;
      case 10007:
        errorMessage = "ADAPTER ERROR";
        fatality = false;
        redirect = false;
        break;
      case 10008:
        errorMessage = "SIMILAR COMPANY";
        fatality = false;
        redirect = false;
        break;
      case 10009:
        errorMessage = "UNAUTHORIZED SYSTEM TYPE";
        fatality = false;
        redirect = false;
        break;
      case 10010:
        errorMessage = "CUSTOMER USER ID NOT ELIGIBLE FOR COMPANY";
        fatality = false;
        redirect = false;
        break;
      case 10011:
        errorMessage = "INVALID COMPANY ID";
        fatality = false;
        redirect = false;
        break;
      case 10012:
        errorMessage = "DUPLICATION DETECTED";
        fatality = false;
        redirect = false;
        break;
      case 10013:
        errorMessage = "USER DOES NOT BELONGS TO COMPANY";
        fatality = false;
        redirect = false;
        break;
      case 10014:
        errorMessage = "INVALID USER SYSTEM";
        fatality = false;
        redirect = false;
        break;
      case 10015:
        errorMessage = "INVALID JSON STRUCTURE";
        fatality = false;
        redirect = false;
        break;
      case 10016:
        errorMessage = "ERROR REQUIRED FIELDS";
        fatality = false;
        redirect = false;
        break;
      case 10017:
        errorMessage = "CHECKER AND MAKER USERNAME SIMILAR";
        fatality = false;
        redirect = false;
        break;
      case 10018:
        errorMessage = "CHECKER AND MAKER EMAIL SIMILAR";
        fatality = false;
        redirect = false;
        break;
      case 10019:
        errorMessage = "INVALID MONTH AND YEAR";
        fatality = false;
        redirect = false;
        break;
      case 10020:
        errorMessage = "PAYROLL ALREADY RELOADED";
        fatality = false;
        redirect = false;
        break;
      case 10021:
        errorMessage = "INVALID INVOICE";
        fatality = false;
        redirect = false;
        break;
      case 10022:
        errorMessage = "PAYROLL ALREADY EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10023:
        errorMessage = "BILL FUND SUFFICIENT";
        fatality = false;
        redirect = false;
        break;
      case 10024:
        errorMessage = "ERROR UPLOAD FILE";
        fatality = false;
        redirect = false;
        break;
      case 10025:
        errorMessage = "INVALID RECEIPT";
        fatality = false;
        redirect = false;
        break;
      case 10026:
        errorMessage = "USER DOES NOT HAVE ACCESS TO PAYROLL";
        fatality = false;
        redirect = false;
        break;
      case 10027:
        errorMessage = "RECEIPT PAYMENT AMOUNT EXCEEDED";
        fatality = false;
        redirect = false;
        break;
      case 10028:
        errorMessage = "INVALID BANK RECEIPT";
        fatality = false;
        redirect = false;
        break;
      case 10029:
        errorMessage = "BANK RECEIPT ALREADY APPROVED OR NOTAPPROVED";
        fatality = false;
        redirect = false;
        break;
      // case 10030:
      //   errorMessage = "UNAUTHORIZED DUE TO PERMISSIONS";
      //   fatality = false;
      //   redirect = false;
      //   break;
      case 10031:
        errorMessage = "INVALID RESET PASSWORD TOKEN";
        fatality = false;
        redirect = false;
        break;
      case 10032:
        errorMessage = "PASSWORD RESET TOKEN EXPIRED";
        fatality = false;
        redirect = false;
        break;
      case 10033:
        errorMessage = "PASSWORD VALIDATION FAILED";
        fatality = false;
        redirect = false;
        break;
      case 10034:
        errorMessage = "TOTAL PAYROLL NOT TALLY WITH SPLIT PAYMENT MANAGEMENT";
        fatality = false;
        redirect = false;
        break;
      case 10035:
        errorMessage = "INVALID OR INVOICE STATUS NOT APPROVE";
        fatality = false;
        redirect = false;
        break;
      case 10036:
        errorMessage = "CIG DISBURSEMENT NOT ALLOWED";
        fatality = false;
        redirect = false;
        break;
      case 10037:
        errorMessage = "INVALID URL SEGMENT";
        fatality = false;
        redirect = false;
        break;
      case 10038:
        errorMessage = "COMPANY EMPLOYEE NOT FOUND";
        fatality = false;
        redirect = false;
        break;
      case 10039:
        errorMessage = "SALARY ALREADY DISBURSED";
        fatality = false;
        redirect = false;
        break;
      case 10040:
        errorMessage = "UNABLE TO TRIGGER SALARY";
        fatality = false;
        redirect = false;
        break;
      case 10041:
        errorMessage = "INVALID LOGIN CREDENTIALS";
        fatality = false;
        redirect = false;
        break;
      case 10042:
        errorMessage = "SPLIT PAYMENT MUST SELECT ONE CHANNEL ONLY";
        fatality = false;
        redirect = false;
        break;
      case 10043:
        errorMessage = "USER DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10044:
        errorMessage = "INVALID RESET USERNAME TOKEN";
        fatality = false;
        redirect = false;
        break;
      case 10045:
        errorMessage = "SALARY NOT DISBURSED";
        fatality = false;
        redirect = false;
        break;
      case 10046:
        errorMessage = "SIMILAR USERNAME MAKER AND CHECKER";
        fatality = false;
        redirect = false;
        break;
      case 10047:
        errorMessage = "SIMILAR EMAIL MAKER AND CHECKER";
        fatality = false;
        redirect = false;
        break;
      case 10048:
        errorMessage = "USER ACCOUNT DORMANT";
        fatality = false;
        redirect = false;
        break;
      case 10049:
        errorMessage = "INVALID POSTCODE";
        fatality = false;
        redirect = false;
        break;
      case 10050:
        errorMessage = "BANK RECEIPT AMOUNT NOT TALLY WITH TOTAL INVOICE";
        fatality = false;
        redirect = false;
        break;
      case 10051:
        errorMessage = "CERTIFICATE NOT FOUND";
        fatality = false;
        redirect = false;
        break;
      case 10079:
        errorMessage = "INVALID QUIZ VALIDATION TYPE";
        fatality = false;
        redirect = false;
        break;
      case 10052:
        errorMessage = "TRAINEE MODULE DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10053:
        errorMessage = "TRAINEE COURSE DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10054:
        errorMessage = "TRAINEE USER DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10055:
        errorMessage = "TRAINEE USER ALREADY COMPLETED THE COURSE";
        fatality = false;
        redirect = false;
        break;
      case 10056:
        errorMessage = "TRAINEE USER ALREADY ALLOCATED";
        fatality = false;
        redirect = false;
        break;
      case 10057:
        errorMessage = "ANSWER OPTION DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10058:
        errorMessage = "SIMILAR VALUE ANSWER OPTION";
        fatality = false;
        redirect = false;
        break;
      case 10059:
        errorMessage = "SIMILAR QUESTION NAME";
        fatality = false;
        redirect = false;
        break;
      case 10060:
        errorMessage = "SIMILAR QUIZ NAME";
        fatality = false;
        redirect = false;
        break;
      case 10061:
        errorMessage = "ANSWER TRUE";
        fatality = false;
        redirect = false;
        break;
      case 10062:
        errorMessage = "QUIZ DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10063:
        errorMessage = "MATERIAL DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10064:
        errorMessage = "QUESTION DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10065:
        errorMessage = "TRAINEE MODULE ITEM DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10076:
        errorMessage = "INVALID FILE";
        fatality = false;
        redirect = false;
        break;
      case 10077:
        errorMessage = "TRAINEE MODULE PROGRESS ID DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 10078:
        errorMessage =
          "BANK RECEIPT APPROVE AMOUNT MISMATCH WITH INVOICE AMOUNT";
        fatality = false;
        redirect = false;
        break;
      case 20066:
        errorMessage = "ROUTE REQUIRE FULL ACCESS";
        fatality = false;
        redirect = false;
        break;
      case 20067:
        errorMessage = "ROUTE REQUIRE HALF ACCESS";
        fatality = false;
        redirect = false;
        break;
      case 20068:
        errorMessage = "INVALID OTP AUTHENTICATION";
        fatality = false;
        redirect = false;
        break;
      case 20069:
        errorMessage = "INVALID OTP";
        fatality = false;
        redirect = false;
        break;
      case 20070:
        errorMessage = "OTP EXPIRED";
        fatality = false;
        redirect = false;
        break;
      case 20071:
        errorMessage = "TOKEN INVALIDATED MULTIPLE LOGIN DETECTED";
        fatality = false;
        redirect = false;
        break;
      case 20072:
        errorMessage = "BILLPLZ ALREADY SUCCESS PREVIOUSLY";
        fatality = false;
        redirect = false;
        break;
      case 20073:
        errorMessage = "TEMP REGISTRATION DOES NOT MEET REQUIREMENT";
        fatality = false;
        redirect = false;
        break;
      case 20074:
        errorMessage = "PURCHASE COURSE DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 20075:
        errorMessage = "COMPANY DOES NOT EXIST";
        fatality = false;
        redirect = false;
        break;
      case 777771:
        errorMessage = "LARAVEL REQUIRED FIELD";
        fatality = false;
        redirect = false;
        break;
      case 777772:
        errorMessage = "LARAVEL INVALID EMAIL STRUCTURE";
        fatality = false;
        redirect = false;
        break;
      case 777773:
        errorMessage = "LARAVEL NOT UNIQUE";
        fatality = false;
        redirect = false;
        break;
      case 7000:
        if (validationErrors) {
          tempHtml += "Validation Error <br><ul>";
          if (validationErrors) {
            Object.keys(validationErrors).forEach(function (key) {
              tempHtml +=
                "<li><strong>" +
                key +
                "</strong> field - " +
                validationErrors[key] +
                "</li>";
            });
          }
          tempHtml += "</ul>";
        }
        errorMessage = tempHtml;
        fatality = false;
        redirect = false;
        break;
      case 10030:
        if (resp.data.permissions["ALL_ROUTE_PERMISSIONS"] !== undefined) {
          routePermissions = resp.data.permissions["ALL_ROUTE_PERMISSIONS"];
        }
        errorMessage = await SystemHelper.parsePermission(routePermissions);
        // errorMessage = parsedPermission(routePermissions);
        // errorMessage = 'Permissions Required: <br>';
        // routePermissions.forEach((permission) => {
        // 	errorMessage += `Module: ${permission.module_id}, Right: ${permission.right_id} <br>`;
        // });
        break;
    }

    return {
      responseCode: responseCode.toString(),
      errorMessage: errorMessage,
      fatal: fatality,
      redirect: redirect,
      redirectRoute: resp.redirectRoute,
    };
  },

  dialogHandler(responseCodeProcessorObj, vuexAction = null, type = "success") {
    let defaultParam = {
      title:
        responseCodeProcessorObj.responseCode +
        responseCodeProcessorObj.errorMessage +
        " = SUCCESS",
      html: responseCodeProcessorObj.errorMessage,
      confirmButtonText: "OK",
      heightAuto: false,
      showCancelButton: false,
      customClass: {
        container: "confirmation-swal",
        header: "bg-success",
        confirmButton: "btn btn-sm btn-bayo-primary ml-3",
        cancelButton: "btn btn-sm btn-bayo-secondary ml-3",
      },
    };
    switch (type) {
      case "success":
        defaultParam.title = "&nbsp;";
        defaultParam.customClass.header = "bg-success";
        defaultParam.html =
          '<h6 class="mb-0">' + responseCodeProcessorObj.errorMessage + "</h6>";
        break;
      case "error":
        defaultParam.title = "&nbsp;";
        defaultParam.customClass.header = "bg-danger";
        defaultParam.html =
          '<p class="mb-1 small font-italic">Error - ' +
          responseCodeProcessorObj.responseCode +
          "</p>" +
          '<h6 class="mb-0 text-danger">' +
          responseCodeProcessorObj.errorMessage +
          "</h6>";
        break;
      default:
        defaultParam.title = " ";
        defaultParam.customClass.header = "bg-info";
    }
    Vue.swal(defaultParam).then(() => {
      if (vuexAction) {
        store.dispatch(vuexAction);
      }
      if (
        responseCodeProcessorObj.responseCode == 7777 ||
        responseCodeProcessorObj.responseCode == 6666 ||
        responseCodeProcessorObj.responseCode == 8888 ||
        responseCodeProcessorObj.responseCode == 10060
      ) {
        store.dispatch("logout");
      }
      if (responseCodeProcessorObj.redirect) {
        Router.replace("/dashboard");
      }
      if (responseCodeProcessorObj.redirectRoute) {
        Router.replace(responseCodeProcessorObj.redirectRoute);
      }
    });
  },

  async errorHandler(resp, vuexAction, type) {
    const response = await SystemHelper.responseCodeProcessor(resp);
    SystemHelper.dialogHandler(response, vuexAction, type);
  },

  async successHandler(resp, vuexAction, type) {
    const response = await SystemHelper.responseCodeProcessor(resp);
    SystemHelper.dialogHandler(response, vuexAction, type);
    // SystemHelper.dialogHandler(
    // 	SystemHelper.responseCodeProcessor(resp),
    // 	vuexAction,
    // 	type
    // );
  },

  basicSwalHandler(title, message, variant = "primary") {
    const defaultParam = {
      title: title,
      html: message,
      confirmButtonText: "OK",
      heightAuto: false,
      showCancelButton: false,
      customClass: {
        container: "confirmation-swal",
        header: "bg-info",
        confirmButton: "btn btn-sm btn-" + variant + " ml-3",
        cancelButton: "btn btn-sm btnーsecondary ml-3",
      },
    };

    Vue.swal(defaultParam);
  },
};

export default SystemHelper;
