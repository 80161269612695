import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
import FileSaver from "file-saver";
// import JwtService from "@/core/services/jwt.service";

// actions
export const GET_INVOICE_LIST = "getInvoiceList";
export const GA_DOWNLOAD_INVOICE = "gaDownloadInvoice";

export const GA_DOWNLOAD_BANK_RECEIPT = "gaDownloadBankReceipt";
export const GA_GET_ALL_BANK_RECEIPT = "gaGetAllBankReceipt";
export const GA_GET_PENDING_BANK_RECEIPT = "gaGetPendingBankReceipt";
export const GA_APPROVE_BANK_RECEIPT = "gaApproveBankReceipt";
export const GA_REJECT_BANK_RECEIPT = "gaRejectBankReceipt";

// mutations
export const SET_INVOICE_LIST = "setInvoiceList";
export const SET_GA_ALL_BANK_RECEIPT = "setGaAllBankReceipt";
export const SET_GA_PENDING_BANK_RECEIPT = "setGaPendingBankReceipt";

const state = {
  invoiceList: null,
  gaAllBankReceipt: [],
  gaPendingBankReceipt: [],
};

const getters = {};

const actions = {
  [GET_INVOICE_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getInvoiceList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_INVOICE_LIST, data.data.list.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GA_DOWNLOAD_INVOICE](context, payload) {
    ApiService.setHeader();
    payload.filename = "invoice_" + payload.invoice_id + ".pdf";
    return new Promise((resolve) => {
      ApiService.post("globalAdminDownloadInvoicePdf", payload, {
        responseType: "blob",
      })
        .then(async ({ data }) => {
          try {
            const file = await context.dispatch("fileReaderText", data);
            const parsedFile = JSON.parse(file);
            console.log(parsedFile);
            if (parsedFile.response_code == 2100) {
              FileSaver.saveAs(
                parsedFile.data.pdf.base64_pdf,
                payload.filename
              );
            } else {
              SystemHelper.errorHandler(parsedFile, null, "error");
            }
          } catch (error) {
            SystemHelper.errorHandler(
              {
                description: "Something went wrong while downloading this file",
              },
              null,
              "error"
            );
          }

          resolve(data);
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GA_DOWNLOAD_BANK_RECEIPT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("globalAdminViewBankReceipt", payload, {
        responseType: "blob",
      })
        .then(async ({ data }) => {
          if (data.type != "application/json") {
            FileSaver.saveAs(data, payload.filename);
          } else {
            try {
              const file = await context.dispatch("fileReaderText", data);
              const parsedFile = JSON.parse(file);
              SystemHelper.errorHandler(parsedFile, null, "error");
            } catch (error) {
              SystemHelper.errorHandler(
                {
                  description:
                    "Something went wrong while downloading this file",
                },
                null,
                "error"
              );
            }
          }

          resolve(data);
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GA_GET_ALL_BANK_RECEIPT](context, payload) {
    ApiService.setHeader();
    payload.q = "";
    return new Promise((resolve) => {
      ApiService.post("getBankReceiptApproval", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_GA_ALL_BANK_RECEIPT, data.data.list.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GA_GET_PENDING_BANK_RECEIPT](context, payload) {
    ApiService.setHeader();
    payload.q = "PENDING";
    return new Promise((resolve) => {
      ApiService.post("getBankReceiptApproval", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_GA_PENDING_BANK_RECEIPT, data.data.list.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GA_APPROVE_BANK_RECEIPT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("approveBankReceipt", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GA_REJECT_BANK_RECEIPT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("rejectBankReceipt", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_INVOICE_LIST](state, data) {
    state.invoiceList = data;
  },
  [SET_GA_ALL_BANK_RECEIPT](state, data) {
    state.gaAllBankReceipt = data;
  },
  [SET_GA_PENDING_BANK_RECEIPT](state, data) {
    state.gaPendingBankReceipt = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
