import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
// import JwtService from "@/core/services/jwt.service";

export const GET_TEMP_TRAINEE_USER_PRE_REG_LIST =
  "getTempTraineeUserRegistrationList";
export const CREATE_TEMP_TRAINEE_USER_REGISTRATION =
  "createTempTraineeUserRegistration";

export const GET_TRAINEE_USER_REG_APPROVED_LIST =
  "getTraineeUserRegistrationApproved";
export const GET_TRAINEE_USER_REG_REJECTED_LIST =
  "getTraineeUserRegistrationRejected";
export const GET_TRAINEE_USER_REG_PENDING_LIST =
  "getTraineeUserRegistrationPending";
export const APPROVE_TRAINEE_USER_REGISTRAION =
  "approveTraineeUserRegistration";
export const REJECT_TRAINEE_USER_REGISTRAION = "rejectTraineeUserRegistration";
export const DELETE_TRAINEE_USER = "deleteTraineeUser";
export const GET_TRAINEE_USER_LIST = "getTraineeUserList";

export const GET_TRAINEE_USER_PERMISSION = "getTraineeUserPermission";
export const UPDATE_TRAINEE_USER_PERMISSION = "updateTraineeUserPermission";
export const AUTO_ASSIGN_TRAINEE_GENERAL_PERMISSION =
  "autoAssignTraineeUserGeneralPermission";

export const GET_ASSIGNED_TRAINEE_USER_LIST = "setAssignedTraineeUserList";
export const GET_UNASSIGNED_TRAINEE_USER_LIST = "setUnassignedTraineeUserList";

//MUTATION
export const SET_TEMP_TRAINEE_USER_PRE_REG_LIST =
  "setTempTraineeUserRegistrationList";

export const SET_TRAINEE_USER_REG_APPROVED_LIST =
  "setTraineeUserRegistrationApproved";
export const SET_TRAINEE_USER_REG_REJECTED_LIST =
  "setTraineeUserRegistrationRejected";
export const SET_TRAINEE_USER_REG_PENDING_LIST =
  "setTraineeUserRegistrationPending";
export const SET_TRAINEE_USER_LIST = "setTraineeUserList";
export const SET_TRAINEE_USER_PERMISSION = "setTraineeUserPermission";

export const SET_ASSIGNED_TRAINEE_USER_LIST = "setAssignedTraineeUserList";
export const SET_UNASSIGNED_TRAINEE_USER_LIST = "setUnassignedTraineeUserList";

const state = {
  traineeUserPreRegList: null,
  unassignedTraineeList: null,
  assignedTraineeList: null,
  traineeUserRegApprovedList: null,
  traineeUserRegRejectedList: null,
  traineeUserRegPendingList: null,
  traineeUserList: null,
  traineeUserPermission: null,
};

const getters = {};

const actions = {
  [GET_TEMP_TRAINEE_USER_PRE_REG_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getTempTraineeUserRegistrationList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data);
            context.commit(
              SET_TEMP_TRAINEE_USER_PRE_REG_LIST,
              data.data.temp_trainee_user_registrations
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CREATE_TEMP_TRAINEE_USER_REGISTRATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("createTempTraineeUserRegistration", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_TRAINEE_USER_REG_REJECTED_LIST](context, payload) {
    ApiService.setHeader();
    payload = {
      ...payload,
      q: "REJECTED",
    };
    return new Promise((resolve) => {
      ApiService.post("getTraineeUserRegistrationApproval", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_TRAINEE_USER_REG_REJECTED_LIST, data.data.list); //datatable
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_TRAINEE_USER_REG_APPROVED_LIST](context, payload) {
    ApiService.setHeader();
    payload = {
      ...payload,
      q: "APPROVED_HISTORY_LIST ",
    };
    return new Promise((resolve) => {
      ApiService.post("getTraineeUserRegistrationApproval", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_TRAINEE_USER_REG_APPROVED_LIST, data.data.list); //datatable
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_TRAINEE_USER_REG_PENDING_LIST](context, payload) {
    ApiService.setHeader();
    payload = {
      ...payload,
      q: "PENDING",
    };
    return new Promise((resolve) => {
      ApiService.post("getTraineeUserRegistrationApproval", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_TRAINEE_USER_REG_PENDING_LIST, data.data.list); //datatable
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [APPROVE_TRAINEE_USER_REGISTRAION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("approveTraineeUserRegistration", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [REJECT_TRAINEE_USER_REGISTRAION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("rejectTraineeUserRegistration", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [DELETE_TRAINEE_USER](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("deleteTraineeUser", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_TRAINEE_USER_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getTraineeUserList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_TRAINEE_USER_LIST, data.data.posts);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_ASSIGNED_TRAINEE_USER_LIST](context, payload) {
    payload = { ...payload, assigned_to_course: 1 };
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("traineeUserCourseSelectionList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_ASSIGNED_TRAINEE_USER_LIST, data.data.list);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_UNASSIGNED_TRAINEE_USER_LIST](context, payload) {
    payload = { ...payload, assigned_to_course: 2 };
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("traineeUserCourseSelectionList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_UNASSIGNED_TRAINEE_USER_LIST, data.data.list);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_TRAINEE_USER_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getTraineeUserPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(
              SET_TRAINEE_USER_PERMISSION,
              data.data.fullUpdatedList
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPDATE_TRAINEE_USER_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("updateTraineeUserPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [AUTO_ASSIGN_TRAINEE_GENERAL_PERMISSION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("autoAssignTraineeUserGeneralPermission", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_TEMP_TRAINEE_USER_PRE_REG_LIST](state, data) {
    state.traineeUserPreRegList = data;
  },
  [SET_UNASSIGNED_TRAINEE_USER_LIST](state, data) {
    state.unassignedTraineeList = data;
  },
  [SET_ASSIGNED_TRAINEE_USER_LIST](state, data) {
    state.assignedTraineeList = data;
  },
  [SET_TRAINEE_USER_REG_APPROVED_LIST](state, data) {
    state.traineeUserRegApprovedList = data;
  },
  [SET_TRAINEE_USER_REG_REJECTED_LIST](state, data) {
    state.traineeUserRegRejectedList = data;
  },
  [SET_TRAINEE_USER_REG_PENDING_LIST](state, data) {
    state.traineeUserRegPendingList = data;
  },
  [SET_TRAINEE_USER_LIST](state, data) {
    state.traineeUserList = data;
  },
  [SET_TRAINEE_USER_PERMISSION](state, data) {
    state.traineeUserPermission = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
