import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
// import JwtService from "@/core/services/jwt.service";

export const GET_COMPANY_TRAINEE_COURSE_LIST = "getCompanyTraineeCourseList";
export const CHECKOUT_COMPANY_COURSE = "companyCheckoutCourse";
export const COMPANY_CHECKOUT_SUCCESSFUL = "companyCheckoutSuccessful";

export const GET_LIST_PURCHASE_COURSE = "getListPurchaseCourse";
export const GET_LIST_PURCHASE_COURSE_DETAILS = "getListPurchaseCourseDetails";
export const ASSIGN_TRAINEE_INTO_COURSE = "assignTraineeUserIntoCourse";

//MUTATION
export const SET_COMPANY_TRAINEE_COURSE_LIST = "setCompanyTraineeCourseList";
export const SET_LIST_PURCHASE_COURSE = "setListPurchaseCourse";
export const SET_LIST_PURCHASE_COURSE_DETAILS = "setListPurchaseCourseDetails";

const state = {
  companyTraineeCourseList: null,
  companyPurchasedCourseList: null,
  companyPurchasedCourseListDetails: null,
};

const getters = {};

const actions = {
  [GET_LIST_PURCHASE_COURSE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getListPurchaseCourse", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data);
            context.commit(SET_LIST_PURCHASE_COURSE, data.data.posts);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_LIST_PURCHASE_COURSE_DETAILS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getPurchaseCourseDetails", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_LIST_PURCHASE_COURSE_DETAILS, data.data.posts);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY_TRAINEE_COURSE_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("CompanyGetTraineeCourseList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data);
            context.commit(SET_COMPANY_TRAINEE_COURSE_LIST, data.data.posts);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CHECKOUT_COMPANY_COURSE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("CompanyCheckoutCourse", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [ASSIGN_TRAINEE_INTO_COURSE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("assignTraineeUserIntoCourse", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [COMPANY_CHECKOUT_SUCCESSFUL](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("CompanyCheckoutSuccessful", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_COMPANY_TRAINEE_COURSE_LIST](state, data) {
    state.companyTraineeCourseList = data;
  },
  [SET_LIST_PURCHASE_COURSE](state, data) {
    state.companyPurchasedCourseList = data;
  },
  [SET_LIST_PURCHASE_COURSE_DETAILS](state, data) {
    state.companyPurchasedCourseListDetails = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
