const requireModule = require.context(".", true, /\.module\.js$/);
const modules = {};

requireModule.keys().forEach((filename) => {
  // create the module name from filename
  // remove the module.js extension
  const moduleName = filename
    .replace(/(\.\/|\.module\.js)/g, "")
    .replace("/", "_");

  modules[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

export default modules;
