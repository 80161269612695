import Vue from "vue";
import Router from "vue-router";
import { getUserSystemType } from "@/core/services/user.service";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          meta: {
            userType: getUserSystemType(),
            title: "Dashboard",
          },
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },

        {
          path: "/SA",
          name: "superadmin",
          component: () => import("@/view/pages/superadmin/SuperAdmin.vue"),
          children: [
            {
              meta: {
                userType: getUserSystemType(),
                title: "User Profile",
              },
              path: "user-profile",
              name: "user-profile",
              component: () => import("@/view/pages/Profile.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Global Admin User List",
              },
              path: "global-admin-list",
              name: "global-admin-page",
              component: () =>
                import("@/view/pages/superadmin/GlobalAdminUserList.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Route Permission",
              },
              path: "route-permission",
              name: "route-permission",
              component: () =>
                import("@/view/pages/superadmin/RoutePermission.vue"),
            },
          ],
        },
        {
          path: "/GA",
          name: "globaladmin",
          redirect: "/globaladmin/company-user",
          component: () => import("@/view/pages/globaladmin/GlobalAdmin.vue"),
          children: [
            {
              meta: {
                userType: getUserSystemType(),
                title: "User Profile",
              },
              path: "user-profile",
              name: "user-profile",
              component: () => import("@/view/pages/Profile.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Pre Registration",
              },
              path: "company-user-pre-registration",
              name: "company-user-pre-registration-page",
              component: () =>
                import(
                  "@/view/pages/globaladmin/companyuser/CompanyUserPreRegistration.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Approval",
              },
              path: "company-user-pre-reg-approval",
              name: "company-user-pre-reg-approval-page",
              component: () =>
                import(
                  "@/view/pages/globaladmin/companyuser/CompanyUserPreRegistrationApproval.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Company User",
              },
              path: "company-user",
              name: "company-user-page",
              component: () =>
                import(
                  "@/view/pages/globaladmin/companyuser/CompanyUserList.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Company",
              },
              path: "company",
              name: "company-page",
              component: () =>
                import("@/view/pages/globaladmin/company/CompanyList.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Company > Invoice",
              },
              path: "company/invoice",
              name: "ga-invoice-list",
              component: () =>
                import("@/view/pages/globaladmin/invoice/InvoiceList.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Questions",
              },
              path: "question",
              name: "question-list",
              component: () =>
                import("@/view/pages/globaladmin/question/QuestionList.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Modules",
              },
              path: "modules",
              name: "module-list",
              component: () =>
                import("@/view/pages/globaladmin/modules/ModulesList.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Courses",
              },
              path: "courses",
              name: "course-list",
              component: () =>
                import("@/view/pages/globaladmin/course/CourseList.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Quiz",
              },
              path: "quiz",
              name: "quiz-list",
              component: () =>
                import("@/view/pages/globaladmin/quiz/QuizList.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Material",
              },
              path: "material",
              name: "material-list",
              component: () =>
                import("@/view/pages/globaladmin/material/MaterialList.vue"),
            },
          ],
        },
        {
          path: "/CA",
          name: "clientadmin",
          component: () => import("@/view/pages/clientadmin/ClientAdmin.vue"),
          children: [
            {
              meta: {
                userType: getUserSystemType(),
                title: "User Profile",
              },
              path: "user-profile",
              name: "user-profile",
              component: () => import("@/view/pages/Profile.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Company Information",
              },
              path: "company-info",
              name: "company-info-page",
              component: () =>
                import("@/view/pages/clientadmin/company/CompanyInfo.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Registration Info",
              },
              path: "company-reg-info",
              name: "company-reg-info-page",
              component: () =>
                import(
                  "@/view/pages/clientadmin/company/CompanyTempRegInfo.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Manage Trainee User",
              },
              path: "trainee-user-list",
              name: "trainee-user-list",
              component: () =>
                import(
                  "@/view/pages/clientadmin/traineeuser/TraineeUserList.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Trainee User Pre Registration",
              },
              path: "trainee-user-pre-reg-list",
              name: "trainee-user-pre-reg-list",
              component: () =>
                import(
                  "@/view/pages/clientadmin/traineeuser/TraineeUserPreRegistration.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Trainee User Approval",
              },
              path: "trainee-user-approval",
              name: "trainee-user-approval",
              component: () =>
                import(
                  "@/view/pages/clientadmin/traineeuser/TraineeUserPreRegistrationApproval.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Bank Receipt",
              },
              path: "bank-receipt-list",
              name: "bank-receipt-list",
              component: () =>
                import("@/view/pages/clientadmin/billing/BankReceipt.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Course Available",
              },
              path: "get-course",
              name: "company-course-list",
              component: () =>
                import(
                  "@/view/pages/clientadmin/trainingcourse/AvailableCourseList.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Purchased Course",
              },
              path: "purchased-course",
              name: "purchased-course-list",
              component: () =>
                import(
                  "@/view/pages/clientadmin/trainingcourse/PurchasedCourseList.vue"
                ),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Bill",
              },
              path: "bill-list",
              name: "bill-list",
              component: () =>
                import("@/view/pages/clientadmin/billing/Bill.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Invoice > Payment",
              },
              path: "invoice-list/payment/:invoiceId",
              name: "make-payment-page",
              component: () =>
                import("@/view/pages/clientadmin/billing/MakePayment.vue"),
            },
            {
              meta: {
                userType: getUserSystemType(),
                title: "Invoice",
              },
              path: "invoice-list",
              name: "invoice-list",
              component: () =>
                import("@/view/pages/clientadmin/billing/Invoice.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/trainee",
      name: "trainee",
      redirect: "/trainee/dashboard",
      component: () => import("@/view/pages/traineeuser/Trainee.vue"),
      children: [
        {
          name: "helper",
          path: "helper",
          component: () => import("@/view/pages/Helper"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "Dashboard",
          },
          path: "dashboard",
          name: "trainee-dashboard",
          component: () =>
            import("@/view/pages/traineeuser/TraineeDashboard.vue"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "User Profile",
          },
          path: "user-profile",
          name: "trainee-profile",
          component: () =>
            import("@/view/pages/traineeuser/TraineeProfile.vue"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "Course",
          },
          path: "course/:traineeCourseId",
          name: "trainee-course",
          component: () => import("@/view/pages/traineeuser/TraineeCourse.vue"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "Course > Lesson",
          },
          path: "course/:traineeCourseId/lesson/:lessonType/:lessonId",
          name: "trainee-lesson",
          component: () => import("@/view/pages/traineeuser/TraineeLesson.vue"),
        },
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          meta: {
            userType: getUserSystemType(),
            title: "Error",
          },
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          meta: {
            userType: getUserSystemType(),
            title: "Login",
          },
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "OTP",
          },
          name: "otp-page",
          path: "/otp",
          component: () => import("@/view/pages/auth/OTP"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "Client Admin Login",
          },
          name: "login-client-admin",
          path: "/login-client-admin",
          component: () => import("@/view/pages/auth/LoginClientAdmin"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "Global Admin Login",
          },
          name: "login-global-admin",
          path: "/login-global-admin",
          component: () => import("@/view/pages/auth/LoginGlobalAdmin"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "Super Admin Login",
          },
          name: "login-super-admin",
          path: "/login-super-admin",
          component: () => import("@/view/pages/auth/LoginSuperadmin"),
        },
        {
          meta: {
            userType: getUserSystemType(),
            title: "Registration",
          },
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Login"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
