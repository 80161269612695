import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
import FileSaver from "file-saver";
// import JwtService from "@/core/services/jwt.service";

// actions
export const GET_INVOICE_LIST = "getListOfInvoice";
export const DELETE_INVOICE = "deleteInvoice";
export const VIEW_INVOICE_PDF = "viewInvoicePdf";
export const DOWNLOAD_INVOICE_PDF = "downloadInvoicePdf";

export const CREATE_BILL = "createBill";
export const GET_BILL = "getBill";
export const GET_BILL_STATUS = "getBillStatus";

export const UPLOAD_BANK_RECEIPT = "uploadBankReceipt";
export const CA_DOWNLOAD_BANK_RECEIPT = "caDownloadBankReceipt";
export const GET_BANK_RECEIPT_LIST = "getBankReceiptList";
export const GET_APPROVED_BANK_RECEIPT_LIST = "getApprovedBankReceiptList";
export const GET_REJECTED_BANK_RECEIPT_LIST = "getRejectedBankReceiptList";

// mutations
export const SET_INVOICE_LIST = "setInvoiceList";
export const SET_BILL = "setBill";
export const SET_BILL_STATUS = "setBillStatus";
export const SET_BANK_RECEIPT_LIST = "setBankReceiptList";
export const SET_APPROVED_BANK_RECEIPT_LIST = "setApprovedBankReceiptList";
export const SET_REJECTED_BANK_RECEIPT_LIST = "setRejectedBankReceiptList";
export const SET_TEMP_BILL_LIST = "setTempBillList";
export const RESET_TEMP_BILL_LIST = "resetTempBillList";

const state = {
  invoiceList: null,
  bill: [],
  billStatus: null,
  bankReceiptList: [],
  approvedBankReceiptList: [],
  rejectedBankReceiptList: [],
  tempBillList: null,
};

const getters = {};

const actions = {
  [GET_INVOICE_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getListOfInvoice", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_INVOICE_LIST, data.data.posts);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [DELETE_INVOICE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("deleteInvoice", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VIEW_INVOICE_PDF](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("viewInvoicePdf", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [DOWNLOAD_INVOICE_PDF](context, payload) {
    ApiService.setHeader();
    payload.filename = "invoice_" + payload.invoice_id + ".pdf";
    return new Promise((resolve) => {
      ApiService.post("downloadInvoicePdf", payload, {
        responseType: "blob",
      })
        .then(async ({ data }) => {
          try {
            const file = await context.dispatch("fileReaderText", data);
            const parsedFile = JSON.parse(file);
            console.log(parsedFile);
            if (parsedFile.response_code == 2100) {
              FileSaver.saveAs(
                parsedFile.data.pdf.base64_pdf,
                payload.filename
              );
            } else {
              SystemHelper.errorHandler(parsedFile, null, "error");
            }
          } catch (error) {
            SystemHelper.errorHandler(
              {
                description: "Something went wrong while downloading this file",
              },
              null,
              "error"
            );
          }

          resolve(data);
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CREATE_BILL](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("createBill", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_BILL](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getBillList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_BILL, data.billsList);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_BILL_STATUS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getBillStatus", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_BILL_STATUS, data.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPLOAD_BANK_RECEIPT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("uploadBankReceipt", payload)
        .then(({ data }) => {
          if (data.response_code == 2100) {
            console.log(data.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CA_DOWNLOAD_BANK_RECEIPT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("viewBankReceipt", payload, { responseType: "blob" })
        .then(async ({ data }) => {
          if (data.type != "application/json") {
            FileSaver.saveAs(data, payload.filename);
          } else {
            try {
              const file = await context.dispatch("fileReaderText", data);
              const parsedFile = JSON.parse(file);
              SystemHelper.errorHandler(parsedFile, null, "error");
            } catch (error) {
              SystemHelper.errorHandler(
                {
                  description:
                    "Something went wrong while downloading this file",
                },
                null,
                "error"
              );
            }
          }

          resolve(data);
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_BANK_RECEIPT_LIST](context, payload) {
    ApiService.setHeader();
    payload.q = "PENDING";
    return new Promise((resolve) => {
      ApiService.post("getBankReceiptList", payload)
        .then(({ data }) => {
          console.log(data);
          if (data.response_code == 2100) {
            context.commit(SET_BANK_RECEIPT_LIST, data.data.list.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_APPROVED_BANK_RECEIPT_LIST](context, payload) {
    ApiService.setHeader();
    payload.q = "APPROVED_HISTORY_LIST";
    return new Promise((resolve) => {
      ApiService.post("getBankReceiptList", payload)
        .then(({ data }) => {
          if (data.response_code == 2100) {
            context.commit(SET_APPROVED_BANK_RECEIPT_LIST, data.data.list.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_REJECTED_BANK_RECEIPT_LIST](context, payload) {
    ApiService.setHeader();
    payload.q = "REJECTED";
    return new Promise((resolve) => {
      ApiService.post("getBankReceiptList", payload)
        .then(({ data }) => {
          if (data.response_code == 2100) {
            context.commit(SET_REJECTED_BANK_RECEIPT_LIST, data.data.list.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_INVOICE_LIST](state, data) {
    state.invoiceList = data;
  },
  [SET_BILL](state, data) {
    state.bill = data;
  },
  [SET_BILL_STATUS](state, data) {
    state.billStatus = data;
  },
  [SET_BANK_RECEIPT_LIST](state, data) {
    state.bankReceiptList = data;
  },
  [SET_APPROVED_BANK_RECEIPT_LIST](state, data) {
    state.approvedBankReceiptList = data;
  },
  [SET_REJECTED_BANK_RECEIPT_LIST](state, data) {
    state.rejectedBankReceiptList = data;
  },
  [SET_TEMP_BILL_LIST](state, data) {
    state.tempBillList = data;
  },
  [RESET_TEMP_BILL_LIST](state) {
    state.tempBillList = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
