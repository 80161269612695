import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

// import auto module exporter
import modules from "./modules";

// import auth from "./auth.module";
// import htmlClass from "./htmlclass.module";
// import config from "./config.module";
// import breadcrumbs from "./breadcrumbs.module";
// import profile from "./profile.module";

// import superadmin from "./superadmin/superadmin.module";

// import globaladmin from "./globaladmin/globaladmin.module";
// import trainingcourse from "./globaladmin/trainingcourse.module";
// import GA_billing from "./globaladmin/billing.module";
// import GA_trainee from "./globaladmin/trainee.module";

// import clientadmin_trainee from "./modules/clientadmin/trainee.module";
// import clientadmin_billing from "./modules/clientadmin/billing.module";

Vue.use(Vuex);

// Vuex Persist
const vueLocalStorage = new VuexPersist({
	key: "STORAGE_KEY",
	storage: window.localStorage,
	reducer: (state) => ({
		auth: {
			authStatus: {
				isLoggedIn: state.auth.authStatus.isLoggedIn,
				isTrainee: state.auth.authStatus.isTrainee,
				isClientAdmin: state.auth.authStatus.isClientAdmin,
				isGlobalAdmin: state.auth.authStatus.isGlobalAdmin,
				isSuperAdmin: state.auth.authStatus.isSuperAdmin,
			},
		},
		trainee_course: {
			currentModuleId: state.trainee_course.currentModuleId,
			finalCheckData: state.trainee_course.finalCheckData,
		},
	}),
});

export default new Vuex.Store({
	modules,
	actions: {
		fileReaderText(context, file) {
			const fileReader = new FileReader();

			return new Promise((resolve, reject) => {
				fileReader.onerror = () => {
					fileReader.abort();
					reject(new Error("Problem parsing file"));
				};

				fileReader.onload = () => {
					resolve(fileReader.result);
				};

				fileReader.readAsText(file);
			});
		},
	},
	plugins: [vueLocalStorage.plugin],
	// modules: {
	//   auth,
	//   superadmin,
	//   globaladmin,
	//   trainingcourse,
	//   GA_trainee,
	//   GA_billing,
	//   clientadmin_trainee,
	//   clientadmin_billing,

	//   htmlClass,
	//   config,
	//   breadcrumbs,
	//   profile,
	// },
});
