// Imports
// import UserService from "@/core/services/user.service";
import ApiService from "@/core/services/api.service";
import router from "@/router";

import systemConstant from "@/core/mock/systemConstant.json";
import responseCode from "@/core/mock/responseCode.json";
import UserService from "@/core/services/user.service";

// constants
const trainee = "trainee";
const clientAdmin = "clientAdmin";
const globalAdmin = "globalAdmin";
const superAdmin = "superAdmin";

// Actions
// export const SET_MENU = "setMenu";
export const SET_MENU = "setMenu";
export const SET_MODAL_STATE = "setModalState";
export const SYSTEM_CONSTANTS = "systemConstants";
export const RESPONSE_CODE = "referenceCode";
export const ROUTE_REDIRECT = "routeRedirect";

// Mutations
export const UPDATE_MENU = "updateMenu";
export const SET_MODAL_ACTIVE = "setModalActive";
export const SET_SYSTEM_CONSTANTS = "setSystemConstants";
export const SET_RESPONSE_CODE = "setResponseCode";

// Default State
const getDefaultState = () => {
  return {
    // menu: [],
    activeModal: null,
    systemConstants: systemConstant,
    responseCode: responseCode,
    menu: [],
  };
};

const state = getDefaultState();

const getters = {
  getMenu(state) {
    return state.menu;
  },
};

const actions = {
  [SET_MODAL_STATE](context, modal) {
    context.commit(SET_MODAL_ACTIVE, modal);
  },
  [SYSTEM_CONSTANTS](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("getReferenceCode")
        .then((res) => {
          const data = res.data;

          // save system constants
          if (data.response_code == 2100) {
            const systemConstants = data.data;
            context.commit(SET_SYSTEM_CONSTANTS, systemConstants);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [RESPONSE_CODE](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("getResponseCode")
        .then((res) => {
          const data = res.data;

          // save system constants
          if (data.response_code == 2100) {
            const responseCode = data.data;
            context.commit(SET_RESPONSE_CODE, responseCode);
          }

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ROUTE_REDIRECT](context, payload) {
    router.push(payload.redirect);
  },
  [SET_MENU]({ commit }) {
    commit(UPDATE_MENU);
  },
};

const mutations = {
  [SET_MODAL_ACTIVE](state, modal) {
    state.activeModal = modal;
  },
  [SET_SYSTEM_CONSTANTS](state, systemConstants) {
    state.systemConstants = systemConstants;
  },
  [SET_RESPONSE_CODE](state, responseCode) {
    state.responseCode = responseCode;
  },
  [UPDATE_MENU](state) {
    state.menu = [
      {
        parentMenu: null,
        name: "Dashboard",
        to: "/dashboard",
        icon: "fas fa-tachometer-alt",
        permission: !UserService.sidebarPermission(trainee),
      },
      {
        parentMenu: null,
        name: "Dashboard",
        to: "/trainee/dashboard",
        icon: "fas fa-tachometer-alt",
        permission: UserService.sidebarPermission(trainee),
      },
      {
        name: "Super Admin",
        heading: true,
        subActivePaths: "none",
        permission: UserService.sidebarPermission(superAdmin),
      },
      {
        parentMenu: null,
        name: "Global Admin List",
        icon: "fas fa-users",
        to: "/SA/global-admin-list",
        permission: UserService.sidebarPermission(superAdmin),
      },
      {
        parentMenu: null,
        icon: "fa fa-sitemap",
        name: "Route Permission",
        to: "/SA/route-permission",
        permission: UserService.sidebarPermission(superAdmin),
      },

      {
        icon: "far fa-building",
        name: "Global Admin",
        heading: true,
        subActivePaths: "none",
        permission: UserService.sidebarPermission(globalAdmin),
      },
      {
        name: "Company",
        icon: "far fa-building",
        subActivePaths: "Clients",
        permission: UserService.sidebarPermission(globalAdmin),
        sub: [
          {
            name: "Registered",
            to: "/GA/company",
            permission: UserService.sidebarPermission(globalAdmin),
          },
          {
            name: "Pre Registration",
            to: "/GA/company-user-pre-registration",
            permission: UserService.sidebarPermission(globalAdmin),
          },
        ],
      },
      {
        name: "Company User",
        icon: "fas fa-users",
        subActivePaths: "Clients",
        permission: UserService.sidebarPermission(globalAdmin),
        sub: [
          {
            name: "Registered User",
            to: "/GA/company-user",
            permission: UserService.sidebarPermission(globalAdmin),
          },
        ],
      },
      {
        name: "Contents",
        icon: "fa fa-book",
        subActivePaths: "Clients",
        permission: UserService.sidebarPermission(globalAdmin),
        parentMenu: "ca-contents",
        sub: [
          {
            parentMenu: "ca-contents",
            name: "Material List",
            to: "/GA/material",
            permission: UserService.sidebarPermission(globalAdmin),
          },
          {
            parentMenu: "ca-contents",
            name: "Question List",
            to: "/GA/question",
            permission: UserService.sidebarPermission(globalAdmin),
          },
          {
            parentMenu: "ca-contents",
            name: "Quiz List",
            to: "/GA/quiz",
            permission: UserService.sidebarPermission(globalAdmin),
          },
          {
            parentMenu: "ca-contents",
            name: "Module List",
            to: "/GA/modules",
            permission: UserService.sidebarPermission(globalAdmin),
          },
          {
            parentMenu: "ca-contents",
            name: "Course List",
            to: "/GA/courses",
            permission: UserService.sidebarPermission(globalAdmin),
          },
        ],
      },

      {
        name: "Client Admin",
        heading: true,
        subActivePaths: "none",
        permission: UserService.sidebarPermission(clientAdmin),
      },
      {
        parentMenu: "ca-company",
        name: "Company",
        icon: "fas fa-building",
        subActivePaths: "ca-company",
        permission: UserService.sidebarPermission(clientAdmin),
        sub: [
          {
            parentMenu: "ca-company",
            name: "Company Info",
            to: "/CA/company-info",
            permission: UserService.sidebarPermission(clientAdmin),
          },
          {
            parentMenu: "ca-company",
            name: "Company Registration Info",
            to: "/CA/company-reg-info",
            permission: UserService.sidebarPermission(clientAdmin),
          },
        ],
      },
      {
        name: "Trainee User",
        icon: "fas fa-users",
        subActivePaths: "ca-trainee-user",
        parentMenu: "ca-trainee-user",
        permission: UserService.sidebarPermission(clientAdmin),
        sub: [
          {
            parentMenu: "ca-trainee-user",
            name: "Manage Permission",
            to: "/CA/trainee-user-list",
            permission: UserService.sidebarPermission(clientAdmin),
          },
          {
            parentMenu: "ca-trainee-user",
            name: "Manage User",
            to: "/CA/trainee-user-approval",
            permission: UserService.sidebarPermission(clientAdmin),
          },
        ],
      },
      {
        name: "Course",
        icon: "fa fa-book",
        subActivePaths: "ca-course",
        parentMenu: "ca-course",
        permission: UserService.sidebarPermission(clientAdmin),
        sub: [
          {
            parentMenu: "ca-course",
            name: "Purchased Course",
            to: "/CA/purchased-course",
            permission: UserService.sidebarPermission(clientAdmin),
          },
          {
            parentMenu: "ca-course",
            name: "Get Course",
            to: "/CA/get-course",
            permission: UserService.sidebarPermission(clientAdmin),
          },
        ],
      },
      {
        name: "Billing",
        icon: "fa fa-credit-card",
        subActivePaths: "ca-billing",
        parentMenu: "ca-billing",
        permission: UserService.sidebarPermission(clientAdmin),
        sub: [
          // {
          //   parentMenu: "ca-billing",
          //   name: "Bank Receipt",
          //   to: "/CA/bank-receipt-list",
          //   permission: UserService.sidebarPermission(clientAdmin),
          // },
          {
            parentMenu: "ca-billing",
            name: "Invoice",
            to: "/CA/invoice-list",
            permission: UserService.sidebarPermission(clientAdmin),
          },
        ],
      },
    ];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
