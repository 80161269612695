import UiHelper from "@/core/services/uihelper.service";
import { SYSTEM_CONSTANTS } from "@/core/services/store/modules/ui.module";

import store from "@/core/services/store";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import SystemHelper from "@/core/services/systemhelper.service";
import APP_SETTINGS from "@/core/config/config";

export default {
  data() {
    return {
      globalCurrency: APP_SETTINGS.CURRENCY,
      debugMode: true,
      localMessages: UiHelper.validationMessages(),
      defaultPayload: {
        length: 100,
        start: 0,
        q: "",
      },
      defaultSwalConf: {
        title: "Are You Sure?",
        html: "Are you sure?",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
        backdrop: true,
        heightAuto: false,
        customClass: {
          container: "confirmation-swal",
          header: "bg-primary",
          confirmButton: "btn btn-sm btn-primary ml-3",
          cancelButton: "btn btn-sm btn-secondary ml-3",
          input: "form-control",
        },
      },
    };
  },
  computed: {
    SystemConstants() {
      const constants = this.$store.state.ui.systemConstants;
      if (
        typeof constants == "object" &&
        constants !== null &&
        Object.keys(constants).length
      ) {
        return constants;
      } else {
        return {};
      }
    },
  },
  methods: {
    //TODO: proper processing
    InvoiceStatusConstant(id) {
      const systemConstants = this.$store.state.ui.systemConstants;
      let billStatus = systemConstants.billStatus;

      function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
      }

      return getKeyByValue(billStatus, id);
    },
    // array param must have actions & payload property
    async dispatchAllActions(vuexActions) {
      let error = false;
      for (const vuex of vuexActions) {
        await store.dispatch(vuex.action, vuex.payload).then((res) => {
          const data = res;
          if (data.response_code != 2100) {
            SystemHelper.errorHandler(data, null, "error");
            error = true;
          }
        });
        if (error) {
          break;
        }
      }
      return error;
    },
    async getSystemConstants() {
      return await this.$store.dispatch(SYSTEM_CONSTANTS);
    },
    modalVisibility(id) {
      return store.state.ui.activeModal === id;
    },
    openModal(id) {
      console.log(id);
      // this.$bvModal.show(id);
      store.dispatch(SET_MODAL_STATE, id);
    },
    closeModal(id) {
      console.log(id);
      this.$bvModal.hide(id);
      store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
