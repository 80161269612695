import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";

import FileSaver from "file-saver";

export const GET_VIEW_QUIZ = "getViewQuiz";
export const GET_VIEW_MATERIAL = "getViewMaterial";
export const GET_VIEW_COURSE_ENROLLED = "getViewCourseEnroll";
export const GET_VIEW_COURSE_PROGRESS = "getCourseProgress";
export const SUBMIT_COURSE_PROGRESS = "submitProgress";
export const START_RESUME_COURSE = "startCourse";
export const GET_TRAINEE_MODULE_PROGRESS = "getTraineeModuleProgress";
export const SET_CURRENT_LESSON_STATE = "setCurrentLessonState";
export const FORWARD_NEXT_MATERIAL = "forwardToNextMaterial";
export const DOWNLOAD_TRAINEE_CERTIFICATE = "downloadTraineeCertificate";
export const GET_VIEW_TRAINEE_COURSE_COVER_PAGE = "getViewCourseCoverPage";
export const SET_CURRENT_MODULE_ID = "setCurrentModuleId";
export const SAVE_FINAL_CHECK_DATA = "saveFinalCheckData";
export const GET_PLEDGE = "getPledge";
export const SUBMIT_PLEDGE = "submitPledge";
export const DOWNLOAD_PLEDGE = "downloadPledge";

export const SET_COURSE_PROGRESS = "setCourseProgress";
export const SET_TRAINEE_MODULE_PROGRESS = "setTraineeModuleProgress";
export const SET_VIEW_QUIZ = "setViewQuiz";
export const SET_VIEW_MATERIAL = "setViewMaterial";
export const SET_VIEW_MATERIAL_URL = "setViewMaterialUrl";
export const SET_VIEW_COURSE_ENROLLED = "setViewCourseEnroll";
export const SET_VIEW_COURSE_PROGRESS = "setViewCourseProgress";
export const SET_CURRENT_ACTIVE_LESSON = "setCurrentActiveLesson";
export const SET_CURRENT_ACTIVE_MODULE_ID = "setCurrentActiveModuleId";
export const SET_FINAL_CHECK_DATA = "setFinalCheckData";
export const SET_PLEDGE_DATA = "setPledgeData";

const state = {
	quizQuestionList: null,
	currentActiveLesson: null,
	traineeModuleProgress: null,
	courseEnrolledList: null,
	courseProgressDetail: null,
	materialDetail: null,
	materialUrl: null,
	courseProgressObj: null,
	currentModuleId: null,
	finalCheckData: null,
	pledgeData: {},
};

const getters = {};

const actions = {
	[DOWNLOAD_TRAINEE_CERTIFICATE](context, payload) {
		ApiService.setHeader();
		payload.filename = "cert_" + payload.username + ".pdf";
		return new Promise((resolve) => {
			ApiService.post("getCertificate", payload, {
				responseType: "blob",
			})
				.then(async ({ data }) => {
					try {
						const file = await context.dispatch(
							"fileReaderText",
							data
						);
						const parsedFile = JSON.parse(file);
						console.log(parsedFile);
						if (parsedFile.response_code == 2100) {
							FileSaver.saveAs(
								parsedFile.data.pdf.base64_pdf,
								payload.filename
							);
						} else {
							SystemHelper.errorHandler(
								parsedFile,
								null,
								"error"
							);
						}
					} catch (error) {
						SystemHelper.errorHandler(
							{
								description:
									"Something went wrong while downloading this file",
							},
							null,
							"error"
						);
					}

					resolve(data);
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_VIEW_QUIZ](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getViewQuiz", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_VIEW_QUIZ, data.data.quiz_detail);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_VIEW_MATERIAL](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getViewMaterial", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_VIEW_MATERIAL, data.data);
						resolve(data.data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_VIEW_COURSE_ENROLLED](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getViewCourseEnroll", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(
							SET_VIEW_COURSE_ENROLLED,
							data.data.traineeCoursesList
						);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_VIEW_COURSE_PROGRESS](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getCourseProgress", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_VIEW_COURSE_PROGRESS, data.data);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_VIEW_TRAINEE_COURSE_COVER_PAGE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getViewTraineeCourseCoverPage ", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[SUBMIT_COURSE_PROGRESS](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("submitProgress", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						console.log(data);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[START_RESUME_COURSE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("startCourse", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(SET_COURSE_PROGRESS, data.data.output);
						resolve(data);
					} else {
						if (data.response_code === 10055) {
							context.commit(
								SET_TRAINEE_MODULE_PROGRESS,
								data.data.traineeCourseComplete
							);
							resolve(data);
						} else {
							SystemHelper.errorHandler(data, null, "error");
							resolve(data);
						}
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[FORWARD_NEXT_MATERIAL](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("startCourse", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[SET_CURRENT_LESSON_STATE](context, moduleObj) {
		context.commit(SET_CURRENT_ACTIVE_LESSON, moduleObj);
	},
	[SET_CURRENT_MODULE_ID](context, moduleId) {
		context.commit(SET_CURRENT_ACTIVE_MODULE_ID, moduleId);
	},
	[SAVE_FINAL_CHECK_DATA](context, data) {
		context.commit(SET_FINAL_CHECK_DATA, data);
	},
	[GET_TRAINEE_MODULE_PROGRESS](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("startCourse", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						context.commit(
							SET_TRAINEE_MODULE_PROGRESS,
							data.data.output
						);
						resolve(data);
					} else {
						if (data.response_code === 10055) {
							context.commit(
								SET_TRAINEE_MODULE_PROGRESS,
								data.data.traineeCourseComplete
							);
							resolve(data);
						} else {
							SystemHelper.errorHandler(data, null, "error");
							resolve(data);
						}
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[GET_PLEDGE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("getPledge", payload)
				.then(({ data }) => {
					if (data.response_code == 2100) {
						context.commit(SET_PLEDGE_DATA, data.data.pledge);
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
				});
		});
	},
	[SUBMIT_PLEDGE](context, payload) {
		ApiService.setHeader();
		return new Promise((resolve) => {
			ApiService.post("submitPledge", payload)
				.then(({ data }) => {
					if (data.response_code === 2100) {
						resolve(data);
					} else {
						SystemHelper.errorHandler(data, null, "error");
						resolve(data);
					}
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[DOWNLOAD_PLEDGE](context, payload) {
		ApiService.setHeader();
		let filename = payload.serialNo + ".pdf";
		return new Promise((resolve) => {
			ApiService.post("downloadPledge", payload, {
				responseType: "blob",
			})
				.then(async ({ data }) => {
					try {
						const file = await context.dispatch(
							"fileReaderText",
							data
						);
						const parsedFile = JSON.parse(file);
						console.log(parsedFile);
						if (parsedFile.response_code == 2100) {
							FileSaver.saveAs(
								parsedFile.data.pdf.base64_pdf,
								filename
							);
						} else {
							SystemHelper.errorHandler(
								parsedFile,
								null,
								"error"
							);
						}
					} catch (error) {
						SystemHelper.errorHandler(
							{
								description:
									"Something went wrong while downloading this file",
							},
							null,
							"error"
						);
					}

					resolve(data);
				})
				.catch(({ response }) => {
					SystemHelper.errorHandler(response, null, "error");
					console.log(response);
					// context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
};

const mutations = {
	[SET_COURSE_PROGRESS](state, data) {
		state.courseProgressObj = data;
	},
	[SET_CURRENT_ACTIVE_LESSON](state, moduleObj) {
		state.currentActiveLesson = moduleObj;
		window.localStorage.setItem(
			"currentActiveModule",
			JSON.stringify(moduleObj)
		);
		if (moduleObj === null) {
			state.currentActiveLesson = moduleObj;
			window.localStorage.setItem("currentActiveModule", null);
		}
	},
	[SET_TRAINEE_MODULE_PROGRESS](state, data) {
		state.traineeModuleProgress = data;
	},
	[SET_VIEW_QUIZ](state, data) {
		state.quizQuestionList = data;
	},
	[SET_VIEW_MATERIAL](state, data) {
		state.materialDetail = data;
	},
	[SET_VIEW_MATERIAL_URL](state, data) {
		state.materialUrl = data;
	},
	[SET_VIEW_COURSE_ENROLLED](state, data) {
		state.courseEnrolledList = data;
	},
	[SET_VIEW_COURSE_PROGRESS](state, data) {
		state.courseProgressDetail = data;
	},
	[SET_CURRENT_ACTIVE_MODULE_ID](state, data) {
		state.currentModuleId = data;
	},
	[SET_FINAL_CHECK_DATA](state, data) {
		state.finalCheckData = data;
	},
	[SET_PLEDGE_DATA](state, data) {
		state.pledgeData = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
