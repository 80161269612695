<template>
  <component :is="tag">
    <div class="form-group">
      <FormulateInput
        :type="type"
        :label="label"
        v-model="Input"
        :input-class="[{ 'is-invalid': hasErrors }, 'form-control', extraClass]"
        :placeholder="placeholder"
        :disabled="disabled"
        :rows="rows"
        :options="options"
        label-position="before"
        :maxlength="maxLength"
      >
        <template v-if="label" #label="{ label, id }">
          <label :class="labelClass" :for="id">
            {{ label }}
            <template v-if="modified"
              ><span class="text-info small">* Info change</span></template
            >
            <i
              v-if="tooltipData"
              v-b-tooltip.html="tooltipData.message"
              :class="tooltipData.icon"
            ></i>
          </label>
        </template>
      </FormulateInput>

      <div class="error-message" v-if="hasErrors">
        <div v-for="error in activeErrorMessages" :key="error">
          <i class="fa fa-exclamation-triangle"></i>
          {{ error }}
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

export default {
  name: "BaseInput",
  mixins: [singleErrorExtractorMixin],
  props: {
    tag: {
      type: String,
      default: "div",
    },
    type: {
      type: String,
      default: "text",
    },
    maxLength: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modified: {
      type: Boolean,
      default: false,
    },
    tooltipData: {
      type: Object,
      default: null,
    },
    rows: {
      type: String,
      default: "4",
    },
    options: {
      type: Array,
    },
    labelClass: {
      type: String,
    },
    extraClass: {
      type: String,
    },
  },
  computed: {
    Input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.info-changed.form-control:disabled {
  background-color: rgba(91, 195, 249, 0.3) !important;
  &::before {
    content: "test";
    position: absolute;
    color: #d26a5c;
  }
}
</style>
<style lang="scss" scoped>
.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.85rem;
  color: #d26a5c;
}
.error-message {
  div {
    .fa {
      font-size: 0.85rem;
    }
    .fa-exclamation-triangle {
      color: #d26a5c;
    }
  }
}
</style>
