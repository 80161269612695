import ApiService from "@/core/services/api.service";
import SystemHelper from "@/core/services/systemhelper.service";
// import JwtService from "@/core/services/jwt.service";

export const GET_COMPANY_UPDATE = "getCompanyUpdate";
export const UPDATE_COMPANY = "updateCompany";
export const GET_COMPANY_TEMP_REG_INFO_LIST =
  "getTempRegistrationInformationList";
export const REJECT_COMPANY_TEMP_REG_INFO_LIST =
  "rejectTempRegistrationInformation";
export const APPROVE_COMPANY_TEMP_REG_INFO_LIST =
  "approveTempRegistrationInformation";
export const GET_COMPANY_TEMP_REG_INFO_APPROVED_LIST =
  "getTempRegistrationInformationApprovedList";
export const GET_COMPANY_TEMP_REG_INFO_REJECTED_LIST =
  "getTempRegistrationInformationRejectedList";
export const GET_COMPANY_TEMP_REG_INFO_PENDING_LIST =
  "getTempRegistrationInformationPendingList";

//MUTATION
export const SET_COMPANY_DETAILS = "setCompanyDetails";
export const SET_COMPANY_TEMP_REG_INFO_LIST =
  "setTempRegistrationInformationList";
export const SET_COMPANY_TEMP_REG_INFO_APPROVED_LIST =
  "setTempRegistrationInformationApprovedList";
export const SET_COMPANY_TEMP_REG_INFO_PENDING_LIST =
  "setTempRegistrationInformationPendingList";
export const SET_COMPANY_TEMP_REG_INFO_REJECTED_LIST =
  "setTempRegistrationInformationRejectedList";

const state = {
  companyDetails: null,
  companyTempRegInfoList: null,
  companyTempRegInfoApprovedList: null,
  companyTempRegInfoRejectedList: null,
  companyTempRegInfoPendingList: null,
};

const getters = {};

const actions = {
  [GET_COMPANY_UPDATE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getCompanyUpdate", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data);
            context.commit(SET_COMPANY_DETAILS, data.data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY_TEMP_REG_INFO_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("getTempRegistrationInformationList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            console.log(data.data.list);
            context.commit(SET_COMPANY_TEMP_REG_INFO_LIST, data.data.list);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY_TEMP_REG_INFO_APPROVED_LIST](context, payload) {
    ApiService.setHeader();
    payload = { ...payload, approve_status_id: 1 };
    return new Promise((resolve) => {
      ApiService.post("getTempRegistrationInformationList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(
              SET_COMPANY_TEMP_REG_INFO_APPROVED_LIST,
              data.data.list
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY_TEMP_REG_INFO_REJECTED_LIST](context, payload) {
    ApiService.setHeader();
    payload = { ...payload, approve_status_id: 3 };
    return new Promise((resolve) => {
      ApiService.post("getTempRegistrationInformationList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(
              SET_COMPANY_TEMP_REG_INFO_REJECTED_LIST,
              data.data.list
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY_TEMP_REG_INFO_PENDING_LIST](context, payload) {
    ApiService.setHeader();
    payload = { ...payload, approve_status_id: 2 };
    return new Promise((resolve) => {
      ApiService.post("getTempRegistrationInformationList", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(
              SET_COMPANY_TEMP_REG_INFO_PENDING_LIST,
              data.data.list
            );
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPDATE_COMPANY](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("updateCompany", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [APPROVE_COMPANY_TEMP_REG_INFO_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("approveTempRegistrationInformation", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [REJECT_COMPANY_TEMP_REG_INFO_LIST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve) => {
      ApiService.post("rejectTempRegistrationInformation", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          SystemHelper.errorHandler(response, null, "error");
          console.log(response);
          // context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_COMPANY_TEMP_REG_INFO_LIST](state, data) {
    state.companyTempRegInfoList = data;
  },
  [SET_COMPANY_TEMP_REG_INFO_PENDING_LIST](state, data) {
    state.companyTempRegInfoPendingList = data;
  },
  [SET_COMPANY_TEMP_REG_INFO_REJECTED_LIST](state, data) {
    state.companyTempRegInfoRejectedList = data;
  },
  [SET_COMPANY_TEMP_REG_INFO_APPROVED_LIST](state, data) {
    state.companyTempRegInfoApprovedList = data;
  },
  [SET_COMPANY_DETAILS](state, data) {
    state.companyDetails = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
