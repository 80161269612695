import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import UserService from "@/core/services/user.service";
import SystemHelper from "@/core/services/systemhelper.service";
import Router from "@/router";

// action types
// export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGIN_SUPER_ADMIN = "loginSuperAdmin";
export const LOGIN_GLOBAL_ADMIN = "loginGlobalAdmin";
export const LOGIN_CLIENT_ADMIN = "loginClientAdmin";
export const LOGIN_TRAINEE_USER = "loginTraineeUser";
export const LOGOUT = "logout";
export const PRE_LOGIN = "prelogin";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

export const RESET_PASSWORD = "resetPassword";
export const FORGOT_USERNAME = "forgotUsername";
export const OTP_EMAIL_VALIDATION = "loginEmailOtpValidation";
export const OTP_SMS_VALIDATION = "loginOtpValidation";

// mutation types
export const PURGE_AUTH = "logOut";
export const PURGE_LOGIN = "purgeLogin";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

export const SET_PERSONAL_INFO = "updateUserAccountInfo";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  authStatus: {
    isLoggedIn: false,
    isTrainee: false,
    isClientAdmin: false,
    isGlobalAdmin: false,
    isSuperAdmin: false,
  },
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN_TRAINEE_USER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("loginTraineeUser", credentials)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_AUTH, data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGIN_SUPER_ADMIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("loginSuperadmin", credentials)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_AUTH, data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGIN_CLIENT_ADMIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("loginClientAdmin", credentials)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_AUTH, data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGIN_GLOBAL_ADMIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("loginGlobalAdmin", credentials)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_AUTH, data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [FORGOT_USERNAME](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("forgotUsername", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [OTP_SMS_VALIDATION](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("loginOtpValidation", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_AUTH, data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [OTP_EMAIL_VALIDATION](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("loginEmailOtpValidation", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            context.commit(SET_AUTH, data);
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [RESET_PASSWORD](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("resetPassword", payload)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [PRE_LOGIN](context) {
    context.commit(PURGE_LOGIN);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          if (data.response_code === 2100) {
            resolve(data);
          } else {
            SystemHelper.errorHandler(data, null, "error");
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    console.log(user.data);
    // set Auth Status
    if (user.data.system_type_id == 1) {
      const status = {
        isLoggedIn: true,
        isTrainee: true,
        isClientAdmin: false,
        isGlobalAdmin: false,
        isSuperAdmin: false,
      };
      state.authStatus = status;
      UserService.saveUserAuth(JSON.stringify(status));
    } else if (user.data.system_type_id == 2) {
      const status = {
        isLoggedIn: true,
        isTrainee: false,
        isClientAdmin: true,
        isGlobalAdmin: false,
        isSuperAdmin: false,
      };
      state.authStatus = status;
      UserService.saveUserAuth(JSON.stringify(status));
    } else if (user.data.system_type_id == 3) {
      const status = {
        isLoggedIn: true,
        isTrainee: false,
        isClientAdmin: false,
        isGlobalAdmin: true,
        isSuperAdmin: false,
      };
      state.authStatus = status;
      UserService.saveUserAuth(JSON.stringify(status));
    } else if (user.data.system_type_id == 4) {
      const status = {
        isLoggedIn: true,
        isTrainee: false,
        isClientAdmin: false,
        isGlobalAdmin: false,
        isSuperAdmin: true,
      };
      state.authStatus = status;
      UserService.saveUserAuth(JSON.stringify(status));
    }
    state.isAuthenticated = true;
    state.user = user.data;
    state.errors = {};
    UserService.saveUserData(JSON.stringify(user.data));
    JwtService.saveToken(user.token);
  },
  [SET_PERSONAL_INFO](state, user_info) {
    console.log("-->", state);
    console.log("-->", user_info);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_LOGIN](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    UserService.clearLocalStorage();
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();

    let userData = JSON.parse(UserService.getUserData());
    if (userData && userData.system_type_id === 4) {
      Router.replace("/login-super-admin");
    } else if (userData && userData.system_type_id === 3) {
      Router.replace("/login-global-admin");
    } else if (userData && userData.system_type_id === 2) {
      Router.replace("/login-client-admin");
    } else if (userData && userData.system_type_id === 1) {
      Router.replace("/login");
    } else {
      Router.replace("/login");
    }

    UserService.clearLocalStorage();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
