<template>
  <div>
    <b-row v-if="!!tableSettings">
      <b-col cols="6">
        <b-form-group class="m-2" label-size="sm">
          <template v-if="tableSettings.refresh">
            <b-button @click="onRefresh" size="sm" class="mr-2">
              <i class="fa fa-sync"></i> Refresh
            </b-button>
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          class="m-2"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
        >
          <template v-if="tableSettings.filter">
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      :per-page="tableSettings.perPage"
      :current-page="currentPage"
      :filter="filter"
      striped
      show-empty
      bordered
      hover
      :items="tableItems"
      :fields="tableFields"
    >
      <template v-slot:cell(actions)="row">
        <b-button-group size="sm" v-if="actionButtons">
          <b-button
            v-for="button of actionButtons"
            :size="button.size"
            :variant="button.variant"
            :key="button.id"
            @click="handle_function_call(button.fn_name, row.item)"
          >
            {{ button.text }}
          </b-button>
        </b-button-group>
      </template>
    </b-table>

    <b-pagination
      v-if="rows > tableSettings.perPage && tableSettings.pagination"
      class="pl-0"
      :total-rows="rows"
      v-model="currentPage"
      :per-page="tableSettings.perPage"
    ></b-pagination>

    <!--    <div v-if="debugMode">-->
    <!--      <pre>{{ parent }}</pre>-->
    <!--      <pre>{{ tableSettings }}</pre>-->
    <!--      <pre>{{ vuexObj }}</pre>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "baseTable",
  data() {
    return {
      filter: "",
      currentPage: 1,
    };
  },
  props: {
    tableItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    vuexAction: {
      type: Object,
      default: function () {
        return {};
      },
    },
    modals: {
      type: Object,
      default: function () {
        return {};
      },
    },
    fields: {
      type: Array,
      default: function () {
        return [];
      },
    },
    actionButtons: {
      type: Array,
      default: function () {
        return [];
      },
    },
    tableSettings: {
      type: Object,
      default: function () {
        return {
          row: 100,
          perPage: 20,
          pagination: false,
          refresh: false,
          filter: false,
        };
      },
    },
  },

  computed: {
    tableFields() {
      let tableFields = this.fields;
      let actionFields = [
        {
          key: "Actions",
          class: "text-right",
        },
      ];
      return tableFields.concat(actionFields);
    },
    parent() {
      console.log("-->", this.$parent.$parent.companyId);
      return [];
    },
    vuexObj() {
      return this.vuexAction;
    },
    rows() {
      return this.tableItems.length;
    },
  },
  methods: {
    handle_function_call(function_name, param = null) {
      this[function_name](param);
    },
    onViewDetails(param) {
      // param = JSON.stringify(param);
      console.log("onViewDetails called " + param);
      this.$parent.$parent.selectedRow = param;
      // open permission modal
      this.openModal(this.modals.detailModal);
    },
    onDelete(param) {
      console.log("onDelete called " + param);
      this.$parent.$parent.selectedRow = param;
      param = JSON.stringify(param);
    },
    onRefresh() {
      this.$store.dispatch(
        this.vuexObj.tableList.vuex,
        this.vuexObj.tableList.payload
      );
    },
  },
};
</script>
