export const APP_SETTINGS = {
  BASE_URL: process.env.VUE_APP_API_URL,
  VERSION: "v1/env/web/",
  CURRENCY: "MYR",
};

export const APP_INFO = {
  NAME: "AKUJANJI",
  LOGO_NAME: "AKUJANJI",
  LONG_NAME: "AKUJANJI",
  DEBUG_FLAG: process.env.VUE_APP_DEBUG_FLAG,
  DEBUG_FLAG_NAME: process.env.VUE_APP_DEBUG_FLAG_NAME,
};

export const LOGIN_CONFIG = {
  LOGIN_CHANNEL_ID: 1,
  LOGIN_PAGE_TITLE: "Welcome to " + APP_INFO.LOGO_NAME + " Portal",
  LOGIN_PAGE_DESC:
    "Tackle bribery and prevent corruption within your organization",
};

if (APP_INFO.DEBUG_FLAG) {
  console.table(APP_INFO);
  console.table(APP_SETTINGS);
}

export default APP_SETTINGS;
